a.text-action {
  color: #9e9e9e;
}
a.text-action,
a.text-action:hover,
a.text-action:focus {
  text-decoration: none;
}
a.text-action:hover,
a.text-action:focus {
  color: #bdbdbd;
}
a.text-action .icon + span {
  margin-left: 3px;
}
a.text-like {
  color: #9e9e9e !important;
}
a.text-like,
a.text-like:hover,
a.text-like:focus {
  text-decoration: none;
}
a.text-like.active,
a.text-like:hover,
a.text-like:focus {
  color: #BA262B !important;
}
.text-action + .text-action {
  margin-left: 6px;
}
.img-bordered {
  padding: 3px;
  border: 1px solid #e0e0e0;
}
.img-bordered-primary {
  border-color: #c03b40 !important;
}
.img-bordered-purple {
  border-color: #8e24aa !important;
}
.img-bordered-red {
  border-color: #BA262B !important;
}
.img-bordered-green {
  border-color: #66bb6a !important;
}
.img-bordered-orange {
  border-color: #fb8c00 !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  text-shadow: rgba(0, 0, 0, 0.15) 0 0 1px;
}
h1 .icon:first-child,
h2 .icon:first-child,
h3 .icon:first-child,
h4 .icon:first-child,
h5 .icon:first-child,
h6 .icon:first-child,
.h1 .icon:first-child,
.h2 .icon:first-child,
.h3 .icon:first-child,
.h4 .icon:first-child,
.h5 .icon:first-child,
.h6 .icon:first-child {
  margin-right: 0.5em;
}
mark,
.mark {
  color: #ffffff;
  border-radius: 2px;
}
.drop-cap {
  float: left;
  padding: 5px;
  margin-right: 5px;
  font-family: Georgia;
  font-size: 60px;
  line-height: 50px;
  color: #212121;
}
.drop-cap-reversed {
  color: #ffffff;
  background-color: #212121;
}
.list-icons {
  padding-left: 10px;
  margin-left: 0;
  list-style: none;
}
.list-icons > li {
  margin-top: 6px;
}
.list-icons > li:first-child {
  margin-top: 0;
}
.list-icons > li i {
  float: left;
  width: 1em;
  margin: 0 6px 0 0;
}
.text-primary {
  color: #c03b40;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #992f33;
}
.text-success {
  color: #4caf50;
}
a.text-success:hover,
a.text-success:focus {
  color: #3d8b40;
}
.text-info {
  color: #00bcd4;
}
a.text-info:hover,
a.text-info:focus {
  color: #008fa1;
}
.text-warning {
  color: #ff9800;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #cc7a00;
}
.text-danger {
  color: #c03b40;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #992f33;
}
blockquote {
  color: #616161;
  font-size: 20px;
  border-left-width: 2px;
}
blockquote footer,
blockquote small,
blockquote .small {
  font-size: 14px;
}
.blockquote-reverse {
  border-right-width: 2px;
}
.blockquote {
  padding: 15px 20px;
  border-left-width: 4px;
  border-radius: 3px;
}
.blockquote.blockquote-reverse {
  border-right-width: 4px;
}
.blockquote-success {
  background-color: rgba(76, 175, 80, 0.1);
  border-color: #4caf50;
}
.blockquote-info {
  background-color: rgba(0, 188, 212, 0.1);
  border-color: #00bcd4;
}
.blockquote-warning {
  background-color: rgba(255, 152, 0, 0.1);
  border-color: #ff9800;
}
.blockquote-danger {
  background-color: rgba(192, 59, 64, 0.1);
  border-color: #c03b40;
}
code {
  border: 1px solid #ffcdd2;
}
.container {
  max-width: 100%;
}
@media (min-width: 1600px) {
  .container {
    width: 1310px;
  }
}
@media (min-width: 1600px) {
  .col-xlg-1, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9, .col-xlg-10, .col-xlg-11, .col-xlg-12 {
    float: left;
  }
  .col-xlg-12 {
    width: 100%;
  }
  .col-xlg-11 {
    width: 91.66666667%;
  }
  .col-xlg-10 {
    width: 83.33333333%;
  }
  .col-xlg-9 {
    width: 75%;
  }
  .col-xlg-8 {
    width: 66.66666667%;
  }
  .col-xlg-7 {
    width: 58.33333333%;
  }
  .col-xlg-6 {
    width: 50%;
  }
  .col-xlg-5 {
    width: 41.66666667%;
  }
  .col-xlg-4 {
    width: 33.33333333%;
  }
  .col-xlg-3 {
    width: 25%;
  }
  .col-xlg-2 {
    width: 16.66666667%;
  }
  .col-xlg-1 {
    width: 8.33333333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.66666667%;
  }
  .col-xlg-pull-10 {
    right: 83.33333333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.66666667%;
  }
  .col-xlg-pull-7 {
    right: 58.33333333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.66666667%;
  }
  .col-xlg-pull-4 {
    right: 33.33333333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.66666667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333333%;
  }
  .col-xlg-pull-0 {
    right: auto;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.66666667%;
  }
  .col-xlg-push-10 {
    left: 83.33333333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.66666667%;
  }
  .col-xlg-push-7 {
    left: 58.33333333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.66666667%;
  }
  .col-xlg-push-4 {
    left: 33.33333333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.66666667%;
  }
  .col-xlg-push-1 {
    left: 8.33333333%;
  }
  .col-xlg-push-0 {
    left: auto;
  }
  .col-xlg-offset-12 {
    margin-left: 100%;
  }
  .col-xlg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xlg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xlg-offset-9 {
    margin-left: 75%;
  }
  .col-xlg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xlg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xlg-offset-6 {
    margin-left: 50%;
  }
  .col-xlg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xlg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xlg-offset-3 {
    margin-left: 25%;
  }
  .col-xlg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xlg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xlg-offset-0 {
    margin-left: 0%;
  }
}
.col-xlg-1, .col-xlg-2, .col-xlg-3, .col-xlg-4, .col-xlg-5, .col-xlg-6, .col-xlg-7, .col-xlg-8, .col-xlg-9, .col-xlg-10, .col-xlg-11, .col-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.row.no-space {
  margin-right: 0;
  margin-left: 0;
}
.row.no-space > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.row-lg {
  margin-left: -25px;
  margin-right: -25px;
}
.row-lg > .col-xs-1, .row-lg > .col-sm-1, .row-lg > .col-md-1, .row-lg > .col-lg-1, .row-lg > .col-xlg-1, .row-lg > .col-xs-2, .row-lg > .col-sm-2, .row-lg > .col-md-2, .row-lg > .col-lg-2, .row-lg > .col-xlg-2, .row-lg > .col-xs-3, .row-lg > .col-sm-3, .row-lg > .col-md-3, .row-lg > .col-lg-3, .row-lg > .col-xlg-3, .row-lg > .col-xs-4, .row-lg > .col-sm-4, .row-lg > .col-md-4, .row-lg > .col-lg-4, .row-lg > .col-xlg-4, .row-lg > .col-xs-5, .row-lg > .col-sm-5, .row-lg > .col-md-5, .row-lg > .col-lg-5, .row-lg > .col-xlg-5, .row-lg > .col-xs-6, .row-lg > .col-sm-6, .row-lg > .col-md-6, .row-lg > .col-lg-6, .row-lg > .col-xlg-6, .row-lg > .col-xs-7, .row-lg > .col-sm-7, .row-lg > .col-md-7, .row-lg > .col-lg-7, .row-lg > .col-xlg-7, .row-lg > .col-xs-8, .row-lg > .col-sm-8, .row-lg > .col-md-8, .row-lg > .col-lg-8, .row-lg > .col-xlg-8, .row-lg > .col-xs-9, .row-lg > .col-sm-9, .row-lg > .col-md-9, .row-lg > .col-lg-9, .row-lg > .col-xlg-9, .row-lg > .col-xs-10, .row-lg > .col-sm-10, .row-lg > .col-md-10, .row-lg > .col-lg-10, .row-lg > .col-xlg-10, .row-lg > .col-xs-11, .row-lg > .col-sm-11, .row-lg > .col-md-11, .row-lg > .col-lg-11, .row-lg > .col-xlg-11, .row-lg > .col-xs-12, .row-lg > .col-sm-12, .row-lg > .col-md-12, .row-lg > .col-lg-12, .row-lg > .col-xlg-12 {
  padding-left: 25px;
  padding-right: 25px;
}
.table {
  color: #757575;
}
.table > thead > tr > th,
.table > tfoot > tr > th {
  font-weight: 400;
  color: #616161;
}
.table > thead > tr > th {
  border-bottom: 1px solid #e0e0e0;
}
.table > tbody + tbody {
  border-top: 1px solid #e0e0e0;
}
.table a {
  text-decoration: underline;
}
.table th > .checkbox-custom:only-child,
.table td > .checkbox-custom:only-child {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}
.table .success,
.table .warning,
.table .danger,
.table .info {
  color: #ffffff;
}
.table .success a,
.table .warning a,
.table .danger a,
.table .info a {
  color: #ffffff;
}
.table .cell-30 {
  width: 30px;
}
.table .cell-40 {
  width: 40px;
}
.table .cell-50 {
  width: 50px;
}
.table .cell-60 {
  width: 60px;
}
.table .cell-80 {
  width: 80px;
}
.table .cell-100 {
  width: 100px;
}
.table .cell-120 {
  width: 120px;
}
.table .cell-130 {
  width: 130px;
}
.table .cell-150 {
  width: 150px;
}
.table .cell-180 {
  width: 180px;
}
.table .cell-200 {
  width: 200px;
}
.table .cell-250 {
  width: 250px;
}
.table .cell-300 {
  width: 300px;
}
.table-primary thead tr,
.table-success thead tr,
.table-info thead tr,
.table-warning thead tr,
.table-danger thead tr,
.table-dark thead tr {
  color: #ffffff;
}
.table-default thead tr {
  background: #eeeeee;
}
.table-primary thead tr {
  background: #c03b40;
}
.table-success thead tr {
  background: #4caf50;
}
.table-info thead tr {
  background: #00bcd4;
}
.table-warning thead tr {
  background: #ff9800;
}
.table-danger thead tr {
  background: #c03b40;
}
.table-dark thead tr {
  background: #616161;
}
.table-gray thead tr {
  color: #616161;
  background: #bdbdbd;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px;
}
.table-bordered > thead:first-child > tr:first-child > th {
  border: 1px solid #e0e0e0;
}
.table-section + tbody {
  display: none;
}
.table-section-arrow {
  text-align: center;
  font-family: 'Material-Design-Iconic-Font';
  transition: transform 0.15s;
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.table-section-arrow:before {
  content: '\f2f9';
}
.table-section.active tr {
  background-color: #eeeeee;
}
.table-section.active + tbody {
  display: table-row-group;
}
.table-section.active .table-section-arrow {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.form-control {
  padding: 6px 13px;
  transition: box-shadow 0.25s linear, border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select.form-control {
  background: #ffffff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAFCAYAAABB9hwOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiNWZkMzNlMC0zNTcxLTI4NDgtYjA3NC01ZTRhN2RjMWVmNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RTUxRUI3MDdEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RTUxRUI3MDZEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ZWNiNjQzMjYtNDc1Yi01OTQxLWIxYjItNDVkZjU5YjZlODA2IiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6N2RlYzI2YWMtZGI5OC0xMWU1LWIwMjgtY2ZhNDhhOGNjNWY1Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+AXTIGgAAAFRJREFUeNpidI1KSWFgYDBlwASngXjOrqWzGcgBTEC8DIjfo4m/h4qTDUAGfwPi+UD8Hyr2H8r/RqnBIHATiPdC2XuhfIoACxJ7PRDzQmmKAUCAAQDxOxHyb4DjOAAAAABJRU5ErkJggg==) no-repeat center right;
  padding-right: 30px;
}
select.form-control[multiple] {
  padding-right: 15px;
  background: #ffffff;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #4caf50;
}
.has-success .form-control {
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
  border-color: #3d8b40;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #92cf94;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #92cf94;
}
.has-success .input-group-addon {
  color: #4caf50;
  border-color: #4caf50;
  background-color: #ffffff;
}
.has-success .form-control-feedback {
  color: #4caf50;
}
.has-success .form-control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-success .form-control:focus {
  border-color: #4caf50;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(76, 175, 80, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(76, 175, 80, 0.6);
}
.has-success .form-control.focus,
.has-success .form-control:focus {
  border-color: #4caf50;
  box-shadow: none;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #ff9800;
}
.has-warning .form-control {
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
  border-color: #cc7a00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffc166;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffc166;
}
.has-warning .input-group-addon {
  color: #ff9800;
  border-color: #ff9800;
  background-color: #ffffff;
}
.has-warning .form-control-feedback {
  color: #ff9800;
}
.has-warning .form-control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-warning .form-control:focus {
  border-color: #ff9800;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 152, 0, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255, 152, 0, 0.6);
}
.has-warning .form-control.focus,
.has-warning .form-control:focus {
  border-color: #ff9800;
  box-shadow: none;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #c03b40;
}
.has-error .form-control {
  border-color: #c03b40;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #992f33;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #da878a;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #da878a;
}
.has-error .input-group-addon {
  color: #c03b40;
  border-color: #c03b40;
  background-color: #ffffff;
}
.has-error .form-control-feedback {
  color: #c03b40;
}
.has-error .form-control {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.has-error .form-control:focus {
  border-color: #c03b40;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(192, 59, 64, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(192, 59, 64, 0.6);
}
.has-error .form-control.focus,
.has-error .form-control:focus {
  border-color: #c03b40;
  box-shadow: none;
}
.form-group.has-feedback.no-label .form-control-feedback {
  top: 0;
}
.form-group.has-feedback.left-feedback .form-control-feedback {
  right: auto;
  left: 0;
}
.form-group.has-feedback.left-feedback .form-control {
  padding-right: 13px;
  padding-left: 50px;
}
.form-control.square {
  border-radius: 0;
}
.form-control.round {
  border-radius: 200px;
}
textarea.form-control.no-resize {
  resize: none;
}
.input-group-file input[type="text"] {
  background-color: #ffffff;
}
.input-group-file .btn-file {
  position: relative;
  overflow: hidden;
}
.input-group-file .btn-file > .icon {
  margin: 0 3px;
}
.input-group-file .btn-file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  min-width: 100%;
  min-height: 100%;
  text-align: 0;
  cursor: pointer;
  opacity: 0;
}
.help-block {
  margin-top: 7px;
  margin-bottom: 8px;
}
.help-block > .icon {
  margin: 0 5px;
}
.input-sm {
  padding: 6px 11px;
}
.input-lg {
  padding: 6px 16px;
}
.input-search-close {
  color: #000;
  text-shadow: none;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.input-search-close.icon {
  line-height: inherit;
  font-size: inherit;
}
.input-search-close:hover,
.input-search-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
button.input-search-close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
.input-search {
  position: relative;
}
.input-search .form-control {
  border-radius: 200px;
}
.input-search .input-search-icon,
.input-search .input-search-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 36px;
}
.input-search .input-search-close {
  right: 8px;
}
.input-search .input-search-icon + .form-control {
  padding-left: 43.5px;
}
.input-search .input-search-icon {
  left: 8px;
  font-size: 16px;
  color: #9e9e9e;
  text-align: center;
  pointer-events: none;
}
.input-search-btn + .form-control {
  padding-right: 50px;
}
.input-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
  background: transparent;
  border: none;
  border-radius: 0 200px 200px 0;
}
.input-search-btn > .icon {
  margin: 0 3px;
}
.input-search-dark .input-search-icon {
  color: #757575;
}
.input-search-dark .form-control {
  background: #eeeeee;
  box-shadow: none;
}
.input-search-dark .form-control:focus {
  background-color: transparent;
}
.form-inline .form-group {
  margin-right: 20px;
}
.form-inline .form-group:last-child {
  margin-right: 0;
}
.form-inline .control-label {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .form-inline .form-group {
    margin-right: 0;
  }
}
/*@btn-floating-xs-padding:                10px;*/
/*@btn-floating-sm-padding:                13px;*/
/*@btn-floating-lg-padding:                15px;*/
.btn {
  padding: 6px 15px;
  font-size: 14px;
  line-height: 1.57142857;
  border-radius: 3px;
  -webkit-font-smoothing: subpixel-antialiased;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  transition: border 0.2s linear, color 0.2s linear, width 0.2s linear, background-color 0.2s linear;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: 0;
}
.btn:active,
.btn.active {
  box-shadow: none;
}
.btn .icon {
  width: 1em;
  text-align: center;
  line-height: inherit;
  margin: -1px 3px 0;
}
.btn-link {
  box-shadow: none;
}
.btn-block {
  white-space: normal;
}
.btn-lg {
  padding: 10px 18px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 4px;
}
.btn-sm {
  padding: 6px 13px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-squared {
  border-radius: 0;
}
.btn-round {
  border-radius: 1000px;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  color: #757575;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.btn-default.btn-up:before {
  border-bottom-color: #eeeeee;
}
.btn-default.btn-up:hover:before,
.btn-default.btn-up:focus:before {
  border-bottom-color: #f5f5f5;
}
.btn-default.btn-up:active:before,
.btn-default.btn-up.active:before,
.open > .dropdown-toggle.btn-default.btn-up:before {
  border-bottom-color: #e0e0e0;
}
.btn-default.btn-right:before {
  border-left-color: #eeeeee;
}
.btn-default.btn-right:hover:before,
.btn-default.btn-right:focus:before {
  border-left-color: #f5f5f5;
}
.btn-default.btn-right:active:before,
.btn-default.btn-right.active:before,
.open > .dropdown-toggle.btn-default.btn-right:before {
  border-left-color: #e0e0e0;
}
.btn-default.btn-bottom:before {
  border-top-color: #eeeeee;
}
.btn-default.btn-bottom:hover:before,
.btn-default.btn-bottom:focus:before {
  border-top-color: #f5f5f5;
}
.btn-default.btn-bottom:active:before,
.btn-default.btn-bottom.active:before,
.open > .dropdown-toggle.btn-default.btn-bottom:before {
  border-top-color: #e0e0e0;
}
.btn-default.btn-left:before {
  border-right-color: #eeeeee;
}
.btn-default.btn-left:hover:before,
.btn-default.btn-left:focus:before {
  border-right-color: #f5f5f5;
}
.btn-default.btn-left:active:before,
.btn-default.btn-left.active:before,
.open > .dropdown-toggle.btn-default.btn-left:before {
  border-right-color: #e0e0e0;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  background-color: #C75155;
  border-color: #C75155;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #BA262B;
  border-color: #BA262B;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  background-color: #BA262B;
  border-color: #BA262B;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  color: #ffffff;
  background-color: #C75155;
  border-color: #C75155;
}
.btn-primary.btn-up:before {
  border-bottom-color: #c03b40;
}
.btn-primary.btn-up:hover:before,
.btn-primary.btn-up:focus:before {
  border-bottom-color: #C75155;
}
.btn-primary.btn-up:active:before,
.btn-primary.btn-up.active:before,
.open > .dropdown-toggle.btn-primary.btn-up:before {
  border-bottom-color: #BA262B;
}
.btn-primary.btn-right:before {
  border-left-color: #c03b40;
}
.btn-primary.btn-right:hover:before,
.btn-primary.btn-right:focus:before {
  border-left-color: #C75155;
}
.btn-primary.btn-right:active:before,
.btn-primary.btn-right.active:before,
.open > .dropdown-toggle.btn-primary.btn-right:before {
  border-left-color: #BA262B;
}
.btn-primary.btn-bottom:before {
  border-top-color: #c03b40;
}
.btn-primary.btn-bottom:hover:before,
.btn-primary.btn-bottom:focus:before {
  border-top-color: #C75155;
}
.btn-primary.btn-bottom:active:before,
.btn-primary.btn-bottom.active:before,
.open > .dropdown-toggle.btn-primary.btn-bottom:before {
  border-top-color: #BA262B;
}
.btn-primary.btn-left:before {
  border-right-color: #c03b40;
}
.btn-primary.btn-left:hover:before,
.btn-primary.btn-left:focus:before {
  border-right-color: #C75155;
}
.btn-primary.btn-left:active:before,
.btn-primary.btn-left.active:before,
.open > .dropdown-toggle.btn-primary.btn-left:before {
  border-right-color: #BA262B;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
  background-color: #66bb6a;
  border-color: #66bb6a;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-color: #43a047;
  border-color: #43a047;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  background-color: #43a047;
  border-color: #43a047;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  color: #ffffff;
  background-color: #66bb6a;
  border-color: #66bb6a;
}
.btn-success.btn-up:before {
  border-bottom-color: #4caf50;
}
.btn-success.btn-up:hover:before,
.btn-success.btn-up:focus:before {
  border-bottom-color: #66bb6a;
}
.btn-success.btn-up:active:before,
.btn-success.btn-up.active:before,
.open > .dropdown-toggle.btn-success.btn-up:before {
  border-bottom-color: #43a047;
}
.btn-success.btn-right:before {
  border-left-color: #4caf50;
}
.btn-success.btn-right:hover:before,
.btn-success.btn-right:focus:before {
  border-left-color: #66bb6a;
}
.btn-success.btn-right:active:before,
.btn-success.btn-right.active:before,
.open > .dropdown-toggle.btn-success.btn-right:before {
  border-left-color: #43a047;
}
.btn-success.btn-bottom:before {
  border-top-color: #4caf50;
}
.btn-success.btn-bottom:hover:before,
.btn-success.btn-bottom:focus:before {
  border-top-color: #66bb6a;
}
.btn-success.btn-bottom:active:before,
.btn-success.btn-bottom.active:before,
.open > .dropdown-toggle.btn-success.btn-bottom:before {
  border-top-color: #43a047;
}
.btn-success.btn-left:before {
  border-right-color: #4caf50;
}
.btn-success.btn-left:hover:before,
.btn-success.btn-left:focus:before {
  border-right-color: #66bb6a;
}
.btn-success.btn-left:active:before,
.btn-success.btn-left.active:before,
.open > .dropdown-toggle.btn-success.btn-left:before {
  border-right-color: #43a047;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
  background-color: #26c6da;
  border-color: #26c6da;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-color: #00acc1;
  border-color: #00acc1;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  background-color: #00acc1;
  border-color: #00acc1;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  color: #ffffff;
  background-color: #26c6da;
  border-color: #26c6da;
}
.btn-info.btn-up:before {
  border-bottom-color: #00bcd4;
}
.btn-info.btn-up:hover:before,
.btn-info.btn-up:focus:before {
  border-bottom-color: #26c6da;
}
.btn-info.btn-up:active:before,
.btn-info.btn-up.active:before,
.open > .dropdown-toggle.btn-info.btn-up:before {
  border-bottom-color: #00acc1;
}
.btn-info.btn-right:before {
  border-left-color: #00bcd4;
}
.btn-info.btn-right:hover:before,
.btn-info.btn-right:focus:before {
  border-left-color: #26c6da;
}
.btn-info.btn-right:active:before,
.btn-info.btn-right.active:before,
.open > .dropdown-toggle.btn-info.btn-right:before {
  border-left-color: #00acc1;
}
.btn-info.btn-bottom:before {
  border-top-color: #00bcd4;
}
.btn-info.btn-bottom:hover:before,
.btn-info.btn-bottom:focus:before {
  border-top-color: #26c6da;
}
.btn-info.btn-bottom:active:before,
.btn-info.btn-bottom.active:before,
.open > .dropdown-toggle.btn-info.btn-bottom:before {
  border-top-color: #00acc1;
}
.btn-info.btn-left:before {
  border-right-color: #00bcd4;
}
.btn-info.btn-left:hover:before,
.btn-info.btn-left:focus:before {
  border-right-color: #26c6da;
}
.btn-info.btn-left:active:before,
.btn-info.btn-left.active:before,
.open > .dropdown-toggle.btn-info.btn-left:before {
  border-right-color: #00acc1;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  background-color: #ffa726;
  border-color: #ffa726;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-color: #fb8c00;
  border-color: #fb8c00;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  background-color: #fb8c00;
  border-color: #fb8c00;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  color: #ffffff;
  background-color: #ffa726;
  border-color: #ffa726;
}
.btn-warning.btn-up:before {
  border-bottom-color: #ff9800;
}
.btn-warning.btn-up:hover:before,
.btn-warning.btn-up:focus:before {
  border-bottom-color: #ffa726;
}
.btn-warning.btn-up:active:before,
.btn-warning.btn-up.active:before,
.open > .dropdown-toggle.btn-warning.btn-up:before {
  border-bottom-color: #fb8c00;
}
.btn-warning.btn-right:before {
  border-left-color: #ff9800;
}
.btn-warning.btn-right:hover:before,
.btn-warning.btn-right:focus:before {
  border-left-color: #ffa726;
}
.btn-warning.btn-right:active:before,
.btn-warning.btn-right.active:before,
.open > .dropdown-toggle.btn-warning.btn-right:before {
  border-left-color: #fb8c00;
}
.btn-warning.btn-bottom:before {
  border-top-color: #ff9800;
}
.btn-warning.btn-bottom:hover:before,
.btn-warning.btn-bottom:focus:before {
  border-top-color: #ffa726;
}
.btn-warning.btn-bottom:active:before,
.btn-warning.btn-bottom.active:before,
.open > .dropdown-toggle.btn-warning.btn-bottom:before {
  border-top-color: #fb8c00;
}
.btn-warning.btn-left:before {
  border-right-color: #ff9800;
}
.btn-warning.btn-left:hover:before,
.btn-warning.btn-left:focus:before {
  border-right-color: #ffa726;
}
.btn-warning.btn-left:active:before,
.btn-warning.btn-left.active:before,
.open > .dropdown-toggle.btn-warning.btn-left:before {
  border-right-color: #fb8c00;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
  background-color: #C75155;
  border-color: #C75155;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-color: #BA262B;
  border-color: #BA262B;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  background-color: #BA262B;
  border-color: #BA262B;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  color: #ffffff;
  background-color: #C75155;
  border-color: #C75155;
}
.btn-danger.btn-up:before {
  border-bottom-color: #c03b40;
}
.btn-danger.btn-up:hover:before,
.btn-danger.btn-up:focus:before {
  border-bottom-color: #C75155;
}
.btn-danger.btn-up:active:before,
.btn-danger.btn-up.active:before,
.open > .dropdown-toggle.btn-danger.btn-up:before {
  border-bottom-color: #BA262B;
}
.btn-danger.btn-right:before {
  border-left-color: #c03b40;
}
.btn-danger.btn-right:hover:before,
.btn-danger.btn-right:focus:before {
  border-left-color: #C75155;
}
.btn-danger.btn-right:active:before,
.btn-danger.btn-right.active:before,
.open > .dropdown-toggle.btn-danger.btn-right:before {
  border-left-color: #BA262B;
}
.btn-danger.btn-bottom:before {
  border-top-color: #c03b40;
}
.btn-danger.btn-bottom:hover:before,
.btn-danger.btn-bottom:focus:before {
  border-top-color: #C75155;
}
.btn-danger.btn-bottom:active:before,
.btn-danger.btn-bottom.active:before,
.open > .dropdown-toggle.btn-danger.btn-bottom:before {
  border-top-color: #BA262B;
}
.btn-danger.btn-left:before {
  border-right-color: #c03b40;
}
.btn-danger.btn-left:hover:before,
.btn-danger.btn-left:focus:before {
  border-right-color: #C75155;
}
.btn-danger.btn-left:active:before,
.btn-danger.btn-left.active:before,
.open > .dropdown-toggle.btn-danger.btn-left:before {
  border-right-color: #BA262B;
}
.btn-inverse {
  color: #757575;
  background-color: #ffffff;
  border-color: #eeeeee;
}
.btn-inverse:focus,
.btn-inverse.focus {
  color: #757575;
  background-color: #e6e6e6;
  border-color: #aeaeae;
}
.btn-inverse:hover {
  color: #757575;
  background-color: #e6e6e6;
  border-color: #cfcfcf;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #757575;
  background-color: #e6e6e6;
  border-color: #cfcfcf;
}
.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #757575;
  background-color: #d4d4d4;
  border-color: #aeaeae;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus {
  background-color: #ffffff;
  border-color: #eeeeee;
}
.btn-inverse .badge {
  color: #ffffff;
  background-color: #757575;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse.focus {
  background-color: #ffffff;
  border-color: #f5f5f5;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  background-color: #ffffff;
  border-color: #e0e0e0;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  color: #bdbdbd;
  background-color: #ffffff;
  border-color: #9e9e9e;
}
.btn-inverse.btn-up:before {
  border-bottom-color: #ffffff;
}
.btn-inverse.btn-up:hover:before,
.btn-inverse.btn-up:focus:before {
  border-bottom-color: #ffffff;
}
.btn-inverse.btn-up:active:before,
.btn-inverse.btn-up.active:before,
.open > .dropdown-toggle.btn-inverse.btn-up:before {
  border-bottom-color: #ffffff;
}
.btn-inverse.btn-right:before {
  border-left-color: #ffffff;
}
.btn-inverse.btn-right:hover:before,
.btn-inverse.btn-right:focus:before {
  border-left-color: #ffffff;
}
.btn-inverse.btn-right:active:before,
.btn-inverse.btn-right.active:before,
.open > .dropdown-toggle.btn-inverse.btn-right:before {
  border-left-color: #ffffff;
}
.btn-inverse.btn-bottom:before {
  border-top-color: #ffffff;
}
.btn-inverse.btn-bottom:hover:before,
.btn-inverse.btn-bottom:focus:before {
  border-top-color: #ffffff;
}
.btn-inverse.btn-bottom:active:before,
.btn-inverse.btn-bottom.active:before,
.open > .dropdown-toggle.btn-inverse.btn-bottom:before {
  border-top-color: #ffffff;
}
.btn-inverse.btn-left:before {
  border-right-color: #ffffff;
}
.btn-inverse.btn-left:hover:before,
.btn-inverse.btn-left:focus:before {
  border-right-color: #ffffff;
}
.btn-inverse.btn-left:active:before,
.btn-inverse.btn-left.active:before,
.open > .dropdown-toggle.btn-inverse.btn-left:before {
  border-right-color: #ffffff;
}
.btn-dark {
  color: #ffffff;
  background-color: #616161;
  border-color: #616161;
}
.btn-dark:focus,
.btn-dark.focus {
  color: #ffffff;
  background-color: #484848;
  border-color: #212121;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #484848;
  border-color: #424242;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  color: #ffffff;
  background-color: #484848;
  border-color: #424242;
}
.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
  color: #ffffff;
  background-color: #363636;
  border-color: #212121;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  background-image: none;
}
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus {
  background-color: #616161;
  border-color: #616161;
}
.btn-dark .badge {
  color: #616161;
  background-color: #ffffff;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus {
  background-color: #757575;
  border-color: #757575;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  background-color: #424242;
  border-color: #424242;
}
.btn-dark:active:hover,
.btn-dark.active:hover,
.open > .dropdown-toggle.btn-dark:hover,
.btn-dark:active:focus,
.btn-dark.active:focus,
.open > .dropdown-toggle.btn-dark:focus,
.btn-dark:active.focus,
.btn-dark.active.focus,
.open > .dropdown-toggle.btn-dark.focus {
  background-color: #424242;
  border-color: #424242;
}
.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled.focus,
.btn-dark[disabled].focus,
fieldset[disabled] .btn-dark.focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
  color: #ffffff;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}
.btn-dark.btn-up:before {
  border-bottom-color: #616161;
}
.btn-dark.btn-up:hover:before,
.btn-dark.btn-up:focus:before {
  border-bottom-color: #757575;
}
.btn-dark.btn-up:active:before,
.btn-dark.btn-up.active:before,
.open > .dropdown-toggle.btn-dark.btn-up:before {
  border-bottom-color: #424242;
}
.btn-dark.btn-right:before {
  border-left-color: #616161;
}
.btn-dark.btn-right:hover:before,
.btn-dark.btn-right:focus:before {
  border-left-color: #757575;
}
.btn-dark.btn-right:active:before,
.btn-dark.btn-right.active:before,
.open > .dropdown-toggle.btn-dark.btn-right:before {
  border-left-color: #424242;
}
.btn-dark.btn-bottom:before {
  border-top-color: #616161;
}
.btn-dark.btn-bottom:hover:before,
.btn-dark.btn-bottom:focus:before {
  border-top-color: #757575;
}
.btn-dark.btn-bottom:active:before,
.btn-dark.btn-bottom.active:before,
.open > .dropdown-toggle.btn-dark.btn-bottom:before {
  border-top-color: #424242;
}
.btn-dark.btn-left:before {
  border-right-color: #616161;
}
.btn-dark.btn-left:hover:before,
.btn-dark.btn-left:focus:before {
  border-right-color: #757575;
}
.btn-dark.btn-left:active:before,
.btn-dark.btn-left.active:before,
.open > .dropdown-toggle.btn-dark.btn-left:before {
  border-right-color: #424242;
}
.btn-dark:hover,
.btn-dark:focus {
  color: #ffffff;
}
.btn-dark:active,
.btn-dark.active,
.open > .dropdown-toggle.btn-dark {
  color: #ffffff;
}
.btn-dark.btn-flat {
  color: #616161;
}
.btn-flat {
  background: none;
  border: none;
  box-shadow: none;
}
.btn-flat:hover,
.btn-flat:focus {
  box-shadow: none;
}
.btn-flat.disabled {
  color: #9e9e9e;
}
.btn-icon,
.btn.icon {
  padding: 10px;
  font-size: 16px;
  line-height: 1em;
}
.btn-icon.btn-xs,
.btn.icon.btn-xs {
  padding: 4px;
  font-size: 12px;
}
.btn-icon.btn-sm,
.btn.icon.btn-sm {
  padding: 8px;
  font-size: 14px;
}
.btn-icon.btn-lg,
.btn.icon.btn-lg {
  padding: 12px;
  font-size: 20px;
}
.btn-icon.disabled,
.btn.icon.disabled {
  color: #9e9e9e;
}
.btn-icon .icon {
  margin: -1px 0 0;
}
.btn-raised {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.18), 0 2px 4px rgba(0, 0, 0, 0.21);
  transition: box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn-raised:hover,
.btn-raised:active,
.btn-raised.active,
.open > .dropdown-toggle.btn-raised {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.2);
}
.btn-raised.disabled,
.btn-raised[disabled],
fieldset[disabled] .btn-raised {
  box-shadow: none;
}
.btn-floating {
  width: 56px;
  height: 56px;
  padding: 0;
  margin: 0;
  font-size: 36px;
  text-align: center;
  border-radius: 100%;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.btn-floating.btn-xs {
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
}
.btn-floating.btn-sm {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 26px;
}
.btn-floating.btn-lg {
  width: 70px;
  height: 70px;
  padding: 0;
  font-size: 46px;
}
.btn-floating i {
  position: relative;
  top: 0;
}
.btn-animate {
  position: relative;
  overflow: hidden;
}
.btn-animate span {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.btn-animate-side {
  padding: 8px 28px;
}
.btn-animate-side span {
  transition: transform 0.2s ease-out 0s;
}
.btn-animate-side span > .icon {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  opacity: 0;
  -webkit-transform: translate(-20px, -50%);
  -ms-transform: translate(-20px, -50%);
  -o-transform: translate(-20px, -50%);
  transform: translate(-20px, -50%);
  transition: opacity 0.2s ease-out 0s;
}
.btn-animate-side:hover span {
  -webkit-transform: translate(10px, 0px);
  -ms-transform: translate(10px, 0px);
  -o-transform: translate(10px, 0px);
  transform: translate(10px, 0px);
}
.btn-animate-side:hover span > .icon {
  opacity: 1;
}
.btn-animate-side.btn-xs {
  padding: 3px 14px;
}
.btn-animate-side.btn-xs span > .icon {
  left: 5px;
}
.btn-animate-side.btn-xs:hover span {
  -webkit-transform: translate(8px, 0px);
  -ms-transform: translate(8px, 0px);
  -o-transform: translate(8px, 0px);
  transform: translate(8px, 0px);
}
.btn-animate-side.btn-sm {
  padding: 6px 22px;
}
.btn-animate-side.btn-sm span > .icon {
  left: 3px;
}
.btn-animate-side.btn-sm:hover span {
  -webkit-transform: translate(8px, 0px);
  -ms-transform: translate(8px, 0px);
  -o-transform: translate(8px, 0px);
  transform: translate(8px, 0px);
}
.btn-animate-side.btn-lg {
  padding: 10px 33px;
}
.btn-animate-side.btn-lg span > .icon {
  left: -6px;
}
.btn-animate-side.btn-lg:hover span {
  -webkit-transform: translate(14px, 0px);
  -ms-transform: translate(14px, 0px);
  -o-transform: translate(14px, 0px);
  transform: translate(14px, 0px);
}
.btn-animate-vertical span {
  transition: all 0.2s ease-out 0s;
}
.btn-animate-vertical span > .icon {
  position: absolute;
  top: -2px;
  left: 50%;
  display: block;
  font-size: 24px;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  -o-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}
.btn-animate-vertical:hover span {
  -webkit-transform: translate(0, 150%);
  -ms-transform: translate(0, 150%);
  -o-transform: translate(0, 150%);
  transform: translate(0, 150%);
}
.btn-animate-vertical.btn-xs span > .icon {
  top: -5px;
  font-size: 18px;
}
.btn-animate-vertical.btn-sm span > .icon {
  top: -3px;
  font-size: 21px;
}
.btn-animate-vertical.btn-lg span > .icon {
  font-size: 37px;
}
.btn-labeled {
  padding: 0;
  padding-right: 8px;
}
.btn-labeled .btn-label {
  padding: 6px 8px;
  margin-right: 5px;
}
.btn-labeled.btn-xs {
  padding-right: 5px;
}
.btn-labeled.btn-xs .btn-label {
  padding: 1px 4px;
  margin-right: 2px;
}
.btn-labeled.btn-sm {
  padding-right: 13px;
}
.btn-labeled.btn-sm .btn-label {
  padding: 6px 6px;
  margin-right: 10px;
}
.btn-labeled.btn-lg {
  padding-right: 14px;
}
.btn-labeled.btn-lg .btn-label {
  padding: 10px 14px;
  margin-right: 11px;
}
.btn-labeled.btn-block {
  text-align: left;
}
.btn-label {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}
.btn-pill-left {
  border-radius: 500px 0 0 500px;
}
.btn-pill-right {
  border-radius: 0 500px 500px 0;
}
.btn-direction {
  position: relative;
}
.btn-direction:before {
  position: absolute;
  line-height: 0;
  content: '';
  border: 8px solid transparent;
}
.btn-up:before {
  top: -16px;
  left: 50%;
  margin-left: -8px;
  border-bottom-color: #e0e0e0;
}
.btn-right:before {
  top: 50%;
  right: -16px;
  margin-top: -8px;
  border-left-color: #e0e0e0;
}
.btn-bottom:before {
  bottom: -16px;
  left: 50%;
  margin-left: -8px;
  border-top-color: #e0e0e0;
}
.btn-left:before {
  top: 50%;
  left: -16px;
  margin-top: -8px;
  border-right-color: #e0e0e0;
}
.btn-pure,
.btn-pure:hover,
.btn-pure:focus,
.btn-pure:active,
.btn-pure.active,
.open > .dropdown-toggle.btn-pure,
.btn-pure[disabled],
fieldset[disabled] .btn-pure {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.btn-pure:hover,
.btn-pure:hover:hover,
.btn-pure:focus:hover,
.btn-pure:active:hover,
.btn-pure.active:hover,
.open > .dropdown-toggle.btn-pure:hover,
.btn-pure[disabled]:hover,
fieldset[disabled] .btn-pure:hover,
.btn-pure:focus,
.btn-pure:hover:focus,
.btn-pure:focus:focus,
.btn-pure:active:focus,
.btn-pure.active:focus,
.open > .dropdown-toggle.btn-pure:focus,
.btn-pure[disabled]:focus,
fieldset[disabled] .btn-pure:focus,
.btn-pure.focus,
.btn-pure:hover.focus,
.btn-pure:focus.focus,
.btn-pure:active.focus,
.btn-pure.active.focus,
.open > .dropdown-toggle.btn-pure.focus,
.btn-pure[disabled].focus,
fieldset[disabled] .btn-pure.focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.btn-pure.btn-default {
  color: #9e9e9e;
}
.btn-pure.btn-default:hover,
.btn-pure.btn-default:focus,
.btn-pure.btn-default:active,
.btn-pure.btn-default.active,
.open > .dropdown-toggle.btn-pure.btn-default {
  color: #bdbdbd;
}
.btn-pure.btn-default:hover:hover,
.btn-pure.btn-default:focus:hover,
.btn-pure.btn-default:active:hover,
.btn-pure.btn-default.active:hover,
.open > .dropdown-toggle.btn-pure.btn-default:hover,
.btn-pure.btn-default:hover:focus,
.btn-pure.btn-default:focus:focus,
.btn-pure.btn-default:active:focus,
.btn-pure.btn-default.active:focus,
.open > .dropdown-toggle.btn-pure.btn-default:focus,
.btn-pure.btn-default:hover.focus,
.btn-pure.btn-default:focus.focus,
.btn-pure.btn-default:active.focus,
.btn-pure.btn-default.active.focus,
.open > .dropdown-toggle.btn-pure.btn-default.focus {
  color: #bdbdbd;
}
.btn-pure.btn-default:hover .badge,
.btn-pure.btn-default:focus .badge,
.btn-pure.btn-default:active .badge,
.btn-pure.btn-default.active .badge,
.open > .dropdown-toggle.btn-pure.btn-default .badge {
  color: #bdbdbd;
}
.btn-pure.btn-primary {
  color: #c03b40;
}
.btn-pure.btn-primary:hover,
.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:active,
.btn-pure.btn-primary.active,
.open > .dropdown-toggle.btn-pure.btn-primary {
  color: #C75155;
}
.btn-pure.btn-primary:hover:hover,
.btn-pure.btn-primary:focus:hover,
.btn-pure.btn-primary:active:hover,
.btn-pure.btn-primary.active:hover,
.open > .dropdown-toggle.btn-pure.btn-primary:hover,
.btn-pure.btn-primary:hover:focus,
.btn-pure.btn-primary:focus:focus,
.btn-pure.btn-primary:active:focus,
.btn-pure.btn-primary.active:focus,
.open > .dropdown-toggle.btn-pure.btn-primary:focus,
.btn-pure.btn-primary:hover.focus,
.btn-pure.btn-primary:focus.focus,
.btn-pure.btn-primary:active.focus,
.btn-pure.btn-primary.active.focus,
.open > .dropdown-toggle.btn-pure.btn-primary.focus {
  color: #C75155;
}
.btn-pure.btn-primary:hover .badge,
.btn-pure.btn-primary:focus .badge,
.btn-pure.btn-primary:active .badge,
.btn-pure.btn-primary.active .badge,
.open > .dropdown-toggle.btn-pure.btn-primary .badge {
  color: #C75155;
}
.btn-pure.btn-success {
  color: #4caf50;
}
.btn-pure.btn-success:hover,
.btn-pure.btn-success:focus,
.btn-pure.btn-success:active,
.btn-pure.btn-success.active,
.open > .dropdown-toggle.btn-pure.btn-success {
  color: #66bb6a;
}
.btn-pure.btn-success:hover:hover,
.btn-pure.btn-success:focus:hover,
.btn-pure.btn-success:active:hover,
.btn-pure.btn-success.active:hover,
.open > .dropdown-toggle.btn-pure.btn-success:hover,
.btn-pure.btn-success:hover:focus,
.btn-pure.btn-success:focus:focus,
.btn-pure.btn-success:active:focus,
.btn-pure.btn-success.active:focus,
.open > .dropdown-toggle.btn-pure.btn-success:focus,
.btn-pure.btn-success:hover.focus,
.btn-pure.btn-success:focus.focus,
.btn-pure.btn-success:active.focus,
.btn-pure.btn-success.active.focus,
.open > .dropdown-toggle.btn-pure.btn-success.focus {
  color: #66bb6a;
}
.btn-pure.btn-success:hover .badge,
.btn-pure.btn-success:focus .badge,
.btn-pure.btn-success:active .badge,
.btn-pure.btn-success.active .badge,
.open > .dropdown-toggle.btn-pure.btn-success .badge {
  color: #66bb6a;
}
.btn-pure.btn-info {
  color: #00bcd4;
}
.btn-pure.btn-info:hover,
.btn-pure.btn-info:focus,
.btn-pure.btn-info:active,
.btn-pure.btn-info.active,
.open > .dropdown-toggle.btn-pure.btn-info {
  color: #26c6da;
}
.btn-pure.btn-info:hover:hover,
.btn-pure.btn-info:focus:hover,
.btn-pure.btn-info:active:hover,
.btn-pure.btn-info.active:hover,
.open > .dropdown-toggle.btn-pure.btn-info:hover,
.btn-pure.btn-info:hover:focus,
.btn-pure.btn-info:focus:focus,
.btn-pure.btn-info:active:focus,
.btn-pure.btn-info.active:focus,
.open > .dropdown-toggle.btn-pure.btn-info:focus,
.btn-pure.btn-info:hover.focus,
.btn-pure.btn-info:focus.focus,
.btn-pure.btn-info:active.focus,
.btn-pure.btn-info.active.focus,
.open > .dropdown-toggle.btn-pure.btn-info.focus {
  color: #26c6da;
}
.btn-pure.btn-info:hover .badge,
.btn-pure.btn-info:focus .badge,
.btn-pure.btn-info:active .badge,
.btn-pure.btn-info.active .badge,
.open > .dropdown-toggle.btn-pure.btn-info .badge {
  color: #26c6da;
}
.btn-pure.btn-warning {
  color: #ff9800;
}
.btn-pure.btn-warning:hover,
.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:active,
.btn-pure.btn-warning.active,
.open > .dropdown-toggle.btn-pure.btn-warning {
  color: #ffa726;
}
.btn-pure.btn-warning:hover:hover,
.btn-pure.btn-warning:focus:hover,
.btn-pure.btn-warning:active:hover,
.btn-pure.btn-warning.active:hover,
.open > .dropdown-toggle.btn-pure.btn-warning:hover,
.btn-pure.btn-warning:hover:focus,
.btn-pure.btn-warning:focus:focus,
.btn-pure.btn-warning:active:focus,
.btn-pure.btn-warning.active:focus,
.open > .dropdown-toggle.btn-pure.btn-warning:focus,
.btn-pure.btn-warning:hover.focus,
.btn-pure.btn-warning:focus.focus,
.btn-pure.btn-warning:active.focus,
.btn-pure.btn-warning.active.focus,
.open > .dropdown-toggle.btn-pure.btn-warning.focus {
  color: #ffa726;
}
.btn-pure.btn-warning:hover .badge,
.btn-pure.btn-warning:focus .badge,
.btn-pure.btn-warning:active .badge,
.btn-pure.btn-warning.active .badge,
.open > .dropdown-toggle.btn-pure.btn-warning .badge {
  color: #ffa726;
}
.btn-pure.btn-danger {
  color: #c03b40;
}
.btn-pure.btn-danger:hover,
.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:active,
.btn-pure.btn-danger.active,
.open > .dropdown-toggle.btn-pure.btn-danger {
  color: #C75155;
}
.btn-pure.btn-danger:hover:hover,
.btn-pure.btn-danger:focus:hover,
.btn-pure.btn-danger:active:hover,
.btn-pure.btn-danger.active:hover,
.open > .dropdown-toggle.btn-pure.btn-danger:hover,
.btn-pure.btn-danger:hover:focus,
.btn-pure.btn-danger:focus:focus,
.btn-pure.btn-danger:active:focus,
.btn-pure.btn-danger.active:focus,
.open > .dropdown-toggle.btn-pure.btn-danger:focus,
.btn-pure.btn-danger:hover.focus,
.btn-pure.btn-danger:focus.focus,
.btn-pure.btn-danger:active.focus,
.btn-pure.btn-danger.active.focus,
.open > .dropdown-toggle.btn-pure.btn-danger.focus {
  color: #C75155;
}
.btn-pure.btn-danger:hover .badge,
.btn-pure.btn-danger:focus .badge,
.btn-pure.btn-danger:active .badge,
.btn-pure.btn-danger.active .badge,
.open > .dropdown-toggle.btn-pure.btn-danger .badge {
  color: #C75155;
}
.btn-pure.btn-dark {
  color: #616161;
}
.btn-pure.btn-dark:hover,
.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:active,
.btn-pure.btn-dark.active,
.open > .dropdown-toggle.btn-pure.btn-dark {
  color: #757575;
}
.btn-pure.btn-dark:hover:hover,
.btn-pure.btn-dark:focus:hover,
.btn-pure.btn-dark:active:hover,
.btn-pure.btn-dark.active:hover,
.open > .dropdown-toggle.btn-pure.btn-dark:hover,
.btn-pure.btn-dark:hover:focus,
.btn-pure.btn-dark:focus:focus,
.btn-pure.btn-dark:active:focus,
.btn-pure.btn-dark.active:focus,
.open > .dropdown-toggle.btn-pure.btn-dark:focus,
.btn-pure.btn-dark:hover.focus,
.btn-pure.btn-dark:focus.focus,
.btn-pure.btn-dark:active.focus,
.btn-pure.btn-dark.active.focus,
.open > .dropdown-toggle.btn-pure.btn-dark.focus {
  color: #757575;
}
.btn-pure.btn-dark:hover .badge,
.btn-pure.btn-dark:focus .badge,
.btn-pure.btn-dark:active .badge,
.btn-pure.btn-dark.active .badge,
.open > .dropdown-toggle.btn-pure.btn-dark .badge {
  color: #757575;
}
.btn-pure.btn-inverse {
  color: #ffffff;
}
.btn-pure.btn-inverse:hover,
.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:active,
.btn-pure.btn-inverse.active,
.open > .dropdown-toggle.btn-pure.btn-inverse {
  color: #ffffff;
}
.btn-pure.btn-inverse:hover:hover,
.btn-pure.btn-inverse:focus:hover,
.btn-pure.btn-inverse:active:hover,
.btn-pure.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-pure.btn-inverse:hover,
.btn-pure.btn-inverse:hover:focus,
.btn-pure.btn-inverse:focus:focus,
.btn-pure.btn-inverse:active:focus,
.btn-pure.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-pure.btn-inverse:focus,
.btn-pure.btn-inverse:hover.focus,
.btn-pure.btn-inverse:focus.focus,
.btn-pure.btn-inverse:active.focus,
.btn-pure.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-pure.btn-inverse.focus {
  color: #ffffff;
}
.btn-pure.btn-inverse:hover .badge,
.btn-pure.btn-inverse:focus .badge,
.btn-pure.btn-inverse:active .badge,
.btn-pure.btn-inverse.active .badge,
.open > .dropdown-toggle.btn-pure.btn-inverse .badge {
  color: #ffffff;
}
.caret {
  transition: .25s;
  -webkit-transform: scale(1.001);
  -ms-transform: scale(1.001);
  -o-transform: scale(1.001);
  transform: scale(1.001);
  border-top: 4px solid;
}
.btn-group .btn + .dropdown-toggle .caret {
  margin-left: 0;
}
.dropdown-toggle.btn .caret {
  margin-left: 0.3em;
}
.dropdown-toggle.btn.btn-xs .caret {
  margin-left: 0;
}
.btn-group > .btn + .dropdown-toggle {
  padding-right: .8em;
  padding-left: .8em;
}
.dropdown-menu {
  margin-top: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  transition: .25s;
}
.dropdown-menu .divider {
  margin: 6px 0;
}
.dropdown-menu > li {
  margin: 2px 0;
}
.dropdown-menu > li > a {
  padding: 6px 13px;
}
.dropdown-menu li .icon:first-child,
.dropdown-menu li > a .icon:first-child {
  margin-right: .5em;
  width: 1em;
  text-align: center;
}
.dropdown-menu.bullet {
  margin-top: 12px;
}
.dropdown-menu.bullet:before,
.dropdown-menu.bullet:after {
  position: absolute;
  left: 10px;
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-width: 0;
}
.dropdown-menu.bullet:before {
  top: -7px;
  border-bottom-color: #e0e0e0;
}
.dropdown-menu.bullet:after {
  top: -6px;
  border-bottom-color: #fff;
}
.dropdown-menu-right.bullet:before,
.dropdown-menu-right.bullet:after {
  right: 10px;
  left: auto;
}
.dropdown-menu.animate {
  overflow: hidden;
}
.dropdown-menu.animate > li {
  animation-name: slide-left;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.dropdown-menu.animate > li:nth-child(1) {
  animation-delay: 0.02s;
}
.dropdown-menu.animate > li:nth-child(2) {
  animation-delay: 0.04s;
}
.dropdown-menu.animate > li:nth-child(3) {
  animation-delay: 0.06s;
}
.dropdown-menu.animate > li:nth-child(4) {
  animation-delay: 0.08s;
}
.dropdown-menu.animate > li:nth-child(5) {
  animation-delay: 0.1s;
}
.dropdown-menu.animate > li:nth-child(6) {
  animation-delay: 0.12s;
}
.dropdown-menu.animate > li:nth-child(7) {
  animation-delay: 0.14s;
}
.dropdown-menu.animate > li:nth-child(8) {
  animation-delay: 0.16s;
}
.dropdown-menu.animate > li:nth-child(9) {
  animation-delay: 0.18s;
}
.dropdown-menu.animate > li:nth-child(10) {
  animation-delay: 0.2s;
}
.dropdown-menu.animate > li.divider {
  -webkit-animation-name: none;
  animation-name: none;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(1) {
  animation-delay: 0.02s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(2) {
  animation-delay: 0.04s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(3) {
  animation-delay: 0.06s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(4) {
  animation-delay: 0.08s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(5) {
  animation-delay: 0.1s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(6) {
  animation-delay: 0.12s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(7) {
  animation-delay: 0.14s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(8) {
  animation-delay: 0.16s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(9) {
  animation-delay: 0.18s;
}
.dropdown-menu.animate.animate-reverse > li:nth-last-child(10) {
  animation-delay: 0.2s;
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 6px;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
}
.dropup .dropdown-menu.bullet,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet {
  margin-bottom: 12px;
}
.dropup .dropdown-menu.bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before,
.dropup .dropdown-menu.bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after {
  top: auto;
  border-top-width: 7px;
  border-bottom-width: 0;
}
.dropup .dropdown-menu.bullet:before,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:before {
  bottom: -7px;
  border-top-color: #e0e0e0;
}
.dropup .dropdown-menu.bullet:after,
.navbar-fixed-bottom .dropdown .dropdown-menu.bullet:after {
  bottom: -6px;
  border-top-color: #fff;
}
.dropdown-menu > .dropdown-header {
  padding: 8px 13px 6px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: default;
}
.dropdown-menu > .dropdown-submenu {
  position: relative;
}
.dropdown-menu > .dropdown-submenu > a {
  position: relative;
}
.dropdown-menu > .dropdown-submenu > a:after {
  position: absolute;
  right: 10px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-top: 6px;
  vertical-align: middle;
  content: '';
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px dashed;
}
.dropdown-menu > .dropdown-submenu .dropdown-menu {
  left: 100%;
  margin: 0;
}
.dropdown-menu > .dropdown-submenu.dropdown-menu-left .dropdown-menu {
  left: -100%;
}
.dropdown-menu > .dropdown-submenu:hover .dropdown-menu {
  display: block;
}
.dropdown .dropdown-submenu .dropdown-menu {
  top: 0;
}
.dropup .dropdown-submenu .dropdown-menu {
  bottom: 0;
}
.dropdown-menu-media {
  padding-top: 0;
  padding-bottom: 0;
  width: 360px;
}
.dropdown-menu-media > li {
  padding: 0;
  margin: 0;
}
.dropdown-menu-media .dropdown-menu-header {
  padding: 20px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}
.dropdown-menu-media .dropdown-menu-header > h3,
.dropdown-menu-media .dropdown-menu-header > h4,
.dropdown-menu-media .dropdown-menu-header > h5 {
  margin: 0;
}
.dropdown-menu-media .dropdown-menu-header .badge,
.dropdown-menu-media .dropdown-menu-header .label {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.dropdown-menu-media .list-group {
  font-size: 12px;
  margin: 0;
  border-radius: 0;
  max-height: 270px;
}
.dropdown-menu-media .list-group-item {
  padding: 0 20px;
  border-radius: 0 !important;
  border: none;
}
.dropdown-menu-media .list-group-item .media {
  padding: 15px 0;
  border-top: 1px solid #e0e0e0;
}
.dropdown-menu-media .list-group-item:first-child .media {
  border-top: none;
}
.dropdown-menu-media > .dropdown-menu-footer {
  border-top: 1px solid #e0e0e0;
  background-color: #eeeeee;
}
.dropdown-menu-media > .dropdown-menu-footer > a {
  padding: 15px 20px !important;
  color: #9e9e9e !important;
}
.dropdown-menu-media > .dropdown-menu-footer > a:hover {
  background-color: transparent !important;
  color: #c03b40 !important;
}
.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn {
  position: absolute;
  right: 0;
}
.dropdown-menu-media > .dropdown-menu-footer > .dropdown-menu-footer-btn:hover {
  color: #c03b40 !important;
  background-color: transparent !important;
}
.dropdown-menu-primary > li > a:hover,
.dropdown-menu-primary > li > a:focus {
  color: #ffffff;
  background-color: #c03b40;
}
.dropdown-menu-primary > .active > a,
.dropdown-menu-primary > .active > a:hover,
.dropdown-menu-primary > .active > a:focus {
  color: #ffffff;
  background-color: #c03b40;
}
.dropdown-menu-success > li > a:hover,
.dropdown-menu-success > li > a:focus {
  color: #ffffff;
  background-color: #4caf50;
}
.dropdown-menu-success > .active > a,
.dropdown-menu-success > .active > a:hover,
.dropdown-menu-success > .active > a:focus {
  color: #ffffff;
  background-color: #4caf50;
}
.dropdown-menu-info > li > a:hover,
.dropdown-menu-info > li > a:focus {
  color: #ffffff;
  background-color: #00bcd4;
}
.dropdown-menu-info > .active > a,
.dropdown-menu-info > .active > a:hover,
.dropdown-menu-info > .active > a:focus {
  color: #ffffff;
  background-color: #00bcd4;
}
.dropdown-menu-warning > li > a:hover,
.dropdown-menu-warning > li > a:focus {
  color: #ffffff;
  background-color: #ff9800;
}
.dropdown-menu-warning > .active > a,
.dropdown-menu-warning > .active > a:hover,
.dropdown-menu-warning > .active > a:focus {
  color: #ffffff;
  background-color: #ff9800;
}
.dropdown-menu-danger > li > a:hover,
.dropdown-menu-danger > li > a:focus {
  color: #ffffff;
  background-color: #c03b40;
}
.dropdown-menu-danger > .active > a,
.dropdown-menu-danger > .active > a:hover,
.dropdown-menu-danger > .active > a:focus {
  color: #ffffff;
  background-color: #c03b40;
}
.dropdown-menu-dark > li > a:hover,
.dropdown-menu-dark > li > a:focus {
  color: #ffffff;
  background-color: #616161;
}
.dropdown-menu-dark > .active > a,
.dropdown-menu-dark > .active > a:hover,
.dropdown-menu-dark > .active > a:focus {
  color: #ffffff;
  background-color: #616161;
}
.btn-group .dropdown-menu > li > a {
  padding: 6px 40px 6px 15px;
}
.btn-group .dropdown-menu > li > a .badge {
  position: absolute;
  right: 15px;
  margin-top: 2px;
}
.btn-group,
.btn-group-vertical {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.btn-group > .btn,
.btn-group-vertical > .btn,
.btn-group .btn-group,
.btn-group-vertical .btn-group,
.btn-group .btn-group-vertical,
.btn-group-vertical .btn-group-vertical {
  box-shadow: none;
}
.btn-group-flat {
  box-shadow: none;
}
.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}
.btn-group:focus .dropdown-toggle {
  transition: .25s;
}
.input-group-addon {
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}
.input-group-btn .btn {
  padding: 6px 10px;
  box-shadow: none;
}
.input-group-btn .btn > .icon {
  vertical-align: bottom;
}
.input-group-btn .dropdown-toggle.btn .caret {
  margin-left: 2px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 1;
}
.nav > li > a {
  overflow: hidden;
}
.nav > li > a:focus {
  outline: none;
}
.nav > li > a .close {
  display: inline-block;
  margin-left: 10px;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: transparent;
}
.nav-quick {
  padding: 0;
  margin-bottom: 22px;
  background-color: #ffffff;
  border-radius: 3px;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.nav-quick li {
  position: relative;
  display: block;
  list-style: none;
  padding: 0;
  text-align: center;
}
.nav-quick a {
  display: block;
  padding: 16px 0;
  color: #757575;
}
.nav-quick a .icon {
  display: block;
  margin-bottom: 0.2em;
  font-size: 32px;
}
.nav-quick a:focus {
  text-decoration: none;
}
.nav-quick a:hover {
  text-decoration: none;
  background-color: #eeeeee;
}
.nav-quick .label,
.nav-quick .badge {
  position: absolute;
  top: 0;
  right: 0;
}
.nav-quick-sm a {
  padding: 12px 0;
}
.nav-quick-sm a .icon {
  font-size: 24px;
}
.nav-quick-lg a {
  padding: 22px 0;
}
.nav-quick-lg a .icon {
  font-size: 40px;
}
.nav-quick-bordered {
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
}
.nav-quick-bordered li {
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}
.nav-pills > li > a {
  transition: border 0.2s linear, color 0.2s linear, background-color 0.2s linear;
}
.nav-pills-rounded > li > a {
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 1000px;
}
.nav-tabs > li > a {
  padding: 10px 20px;
  color: #757575;
  transition: .25s;
}
.nav-tabs > li > a > .icon {
  line-height: 1;
  margin-right: .5em;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #ffffff;
  background-color: #c03b40;
  border-color: transparent;
  border-bottom-color: #c03b40;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 4px 4px 0 0;
}
.nav-tabs.nav-justified > li.active > a,
.nav-tabs.nav-justified > li.active > a:hover,
.nav-tabs.nav-justified > li.active > a:focus {
  border-color: transparent;
  border-bottom-color: #c03b40;
}
.nav-tabs.nav-tabs-bottom {
  border-top: 1px solid #e0e0e0;
  border-bottom: none;
}
.nav-tabs.nav-tabs-bottom > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.nav-tabs.nav-tabs-bottom > li > a {
  border-radius: 0 0 4px 4px;
}
.nav-tabs.nav-tabs-bottom > li > a:hover,
.nav-tabs.nav-tabs-bottom > li > a:focus {
  border-bottom-color: transparent;
  border-top-color: #e0e0e0;
}
.nav-tabs.nav-tabs-bottom.nav-justified {
  border-top: none;
}
.nav-tabs.nav-tabs-bottom.nav-justified > li > a {
  border-bottom-color: transparent;
  border-top-color: #e0e0e0;
}
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a,
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:hover,
.nav-tabs.nav-tabs-bottom.nav-justified > li.active > a:focus {
  border-top: 1px solid #c03b40;
}
.nav-tabs-reverse > li {
  float: right;
}
.nav-tabs-reverse > li > a {
  margin-right: 0;
  margin-left: 2px;
}
.nav-tabs-solid {
  border-bottom-color: #eeeeee;
}
.nav-tabs-solid > li > a:hover {
  border-color: transparent;
}
.nav-tabs-solid > li.active > a,
.nav-tabs-solid > li.active > a:hover,
.nav-tabs-solid > li.active > a:focus {
  color: #757575;
  background-color: #eeeeee;
  border-color: transparent;
}
.nav-tabs-solid ~ .tab-content {
  padding: 20px;
  background-color: #eeeeee;
}
.nav-tabs-solid.nav-justified > li > a {
  border: none;
}
.nav-tabs-solid.nav-justified > li.active > a,
.nav-tabs-solid.nav-justified > li.active > a:hover,
.nav-tabs-solid.nav-justified > li.active > a:focus {
  border: none;
}
.nav-tabs-solid.nav-tabs-bottom > li.active > a,
.nav-tabs-solid.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-solid.nav-tabs-bottom > li.active > a:focus {
  border: none;
}
.nav-tabs-line {
  position: relative;
}
.nav-tabs-line > li > a {
  padding: 10px 20px;
  border-bottom: 2px solid transparent;
}
.nav-tabs-line > li > a:hover,
.nav-tabs-line > li > a:focus {
  background-color: transparent;
}
.nav-tabs-line > li > a:hover {
  border-bottom-color: #bdbdbd;
}
.nav-tabs-line > li.active > a,
.nav-tabs-line > li.active > a:hover,
.nav-tabs-line > li.active > a:focus {
  color: #c03b40;
  background-color: transparent;
  border-bottom: 2px solid transparent;
}
.nav-tabs-line .open > a,
.nav-tabs-line .open > a:hover,
.nav-tabs-line .open > a:focus {
  border-color: transparent;
  border-bottom-color: #bdbdbd;
}
.nav-tabs-line > .nav-tabs-autoline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #c03b40;
  transition-property: left, width;
}
.nav-tabs-line.nav-tabs-bottom > li > a {
  border-top: 2px solid transparent;
  border-bottom: none;
}
.nav-tabs-line.nav-tabs-bottom > li > a:hover {
  border-top-color: #bdbdbd;
  border-bottom-color: transparent;
}
.nav-tabs-line.nav-tabs-bottom > li.active > a,
.nav-tabs-line.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-line.nav-tabs-bottom > li.active > a:focus {
  border-top: 2px solid transparent;
  border-bottom: none;
}
.nav-tabs-line.nav-tabs-bottom > .nav-tabs-autoline {
  top: 0;
  bottom: auto;
}
.nav-tabs-line.nav-justified > li > a {
  margin-bottom: -1px;
  border-bottom: 2px solid #e0e0e0;
}
.nav-tabs-line.nav-justified > li > a:hover {
  border-bottom-color: #bdbdbd;
}
.nav-tabs-line.nav-justified > li.active > a,
.nav-tabs-line.nav-justified > li.active > a:hover,
.nav-tabs-line.nav-justified > li.active > a:focus {
  border-color: transparent;
  border-bottom: 2px solid #e0e0e0;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom {
  border-top: none;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a {
  margin-top: -1px;
  margin-bottom: 0;
  border-top: 2px solid #e0e0e0;
  border-bottom: none;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom > li > a:hover {
  border-top-color: #bdbdbd;
}
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a,
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-line.nav-justified.nav-tabs-bottom > li.active > a:focus {
  border-top-color: #e0e0e0;
  border-bottom: none;
}
.nav-tabs-vertical:before,
.nav-tabs-vertical:after {
  content: " ";
  display: table;
}
.nav-tabs-vertical:after {
  clear: both;
}
.nav-tabs-vertical .nav-tabs {
  float: left;
  border-right: 1px solid #e0e0e0;
  border-bottom: none;
}
.nav-tabs-vertical .nav-tabs > li {
  float: none;
  margin-right: -1px;
  margin-bottom: 0;
}
.nav-tabs-vertical .nav-tabs > li > a {
  padding: 10px 20px;
  margin-right: 0;
  margin-bottom: 2px;
  border-radius: 4px 0 0 4px;
}
.nav-tabs-vertical .nav-tabs > li > a:hover {
  border-bottom-color: transparent;
  border-right-color: #e0e0e0;
}
.nav-tabs-vertical .nav-tabs > li.active > a,
.nav-tabs-vertical .nav-tabs > li.active > a:hover,
.nav-tabs-vertical .nav-tabs > li.active > a:focus {
  border-right-color: #c03b40;
}
.nav-tabs-vertical .nav-tabs-reverse {
  float: right;
  border-right: none;
  border-left: 1px solid #e0e0e0;
}
.nav-tabs-vertical .nav-tabs-reverse > li {
  margin-right: 0;
  margin-left: -1px;
}
.nav-tabs-vertical .nav-tabs-reverse > li > a {
  margin-left: 0;
  border-radius: 0 4px 4px 0;
}
.nav-tabs-vertical .nav-tabs-reverse > li > a:hover {
  border-right-color: transparent;
  border-left-color: #e0e0e0;
}
.nav-tabs-vertical .nav-tabs-reverse > li.active > a,
.nav-tabs-vertical .nav-tabs-reverse > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-reverse > li.active > a:focus {
  border-left-color: #c03b40;
}
.nav-tabs-vertical .nav-tabs-solid {
  border-right-color: #eeeeee;
}
.nav-tabs-vertical .nav-tabs-solid > li > a:hover {
  border-color: transparent;
}
.nav-tabs-vertical .nav-tabs-solid > li.active > a,
.nav-tabs-vertical .nav-tabs-solid > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-solid > li.active > a:focus {
  border-color: transparent;
}
.nav-tabs-vertical .nav-tabs-solid + .tab-content {
  padding: 20px;
}
.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
  border-left-color: #eeeeee;
}
.nav-tabs-vertical .nav-tabs-line > li > a {
  border-right: 2px solid transparent;
  border-bottom: none;
}
.nav-tabs-vertical .nav-tabs-line > li > a:hover {
  border-right-color: #bdbdbd;
}
.nav-tabs-vertical .nav-tabs-line > li.active > a,
.nav-tabs-vertical .nav-tabs-line > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-line > li.active > a:focus {
  border-right: 2px solid transparent;
  border-bottom: none;
}
.nav-tabs-vertical .nav-tabs-line > .nav-tabs-autoline {
  left: auto;
  right: 0;
  width: 2px;
  height: 0;
  transition-property: top, height;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a {
  border-right-width: 1px;
  border-left: 2px solid transparent;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li > a:hover {
  border-color: transparent;
  border-left-color: #bdbdbd;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:hover,
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > li.active > a:focus {
  border-right: 1px solid transparent;
  border-left: 2px solid transparent;
}
.nav-tabs-vertical .nav-tabs-line.nav-tabs-reverse > .nav-tabs-autoline {
  left: 0;
  right: auto;
}
.nav-tabs-vertical .tab-content {
  overflow: hidden;
}
.nav-tabs-inverse .nav-tabs-solid {
  border-bottom-color: #ffffff;
}
.nav-tabs-inverse .nav-tabs-solid > li.active > a,
.nav-tabs-inverse .nav-tabs-solid > li.active > a:hover,
.nav-tabs-inverse .nav-tabs-solid > li.active > a:focus {
  color: #757575;
  background-color: #ffffff;
}
.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid {
  border-right-color: #ffffff;
}
.nav-tabs-inverse.nav-tabs-vertical .nav-tabs-solid.nav-tabs-reverse {
  border-left-color: #ffffff;
}
.nav-tabs-inverse .tab-content {
  background: #ffffff;
}
.nav-tabs-animate .tab-content {
  overflow: hidden;
}
.nav-tabs-lg > li > a {
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.3333333;
}
.nav-tabs-sm > li > a {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}
.navbar-toggle {
  transition: color .25s linear;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 22px 15px;
  line-height: 22px;
  height: 66px;
  margin-top: 0;
  margin-bottom: 0;
  background: transparent !important;
}
.navbar-toggle .icon {
  margin-top: -1px;
}
.navbar-toggle:hover {
  background: transparent !important;
}
.navbar-toggle-left {
  float: left;
  margin-left: 15px;
  margin-right: 0;
}
.navbar {
  border: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  width: 100%;
}
@media (min-width: 768px) {
  .hidden-float {
    display: block;
  }
}
@media (max-width: 767px) {
  .hidden-float {
    display: none !important;
  }
}
.navbar-brand {
  font-weight: 500;
  padding: 22px 20px;
}
.navbar-brand > .navbar-brand-logo {
  display: inline-block;
}
.navbar-brand-logo {
  margin-top: -5px;
  height: 32px;
}
.navbar-brand-text {
  margin-left: 6px;
}
@media (max-width: 767px) {
  .navbar-brand-center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand-center,
  .navbar > .container-fluid .navbar-brand-center {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-mega .container,
  .navbar-mega .container-fluid {
    position: relative;
  }
}
.navbar-mega .dropdown-menu {
  left: auto;
}
.navbar-mega .dropdown-mega {
  position: static;
}
.navbar-mega .mega-content {
  padding: 20px 30px;
}
.navbar-mega .mega-menu {
  min-width: 150px;
  max-width: 100%;
}
.navbar-mega .mega-menu > ul {
  padding-left: 0;
}
.navbar-mega .mega-menu .list-icons {
  margin-bottom: 6px;
}
.navbar-mega .dropdown.dropdown-fw .dropdown-menu {
  right: 5px;
  left: 5px;
}
@media (max-width: 767px) {
  .navbar-mega .dropdown.dropdown-fw .dropdown-menu {
    right: 0;
    left: 0;
  }
}
.navbar-nav > li > a.navbar-avatar,
.navbar-toolbar > li > a.navbar-avatar {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media (max-width: 767px) {
  .navbar-nav > li > a.navbar-avatar {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.navbar-avatar .avatar {
  width: 42px;
}
.navbar-form .icon {
  font-size: 16px;
  color: rgba(66, 66, 66, 0.4);
}
.navbar-form .form-control {
  background-color: #eeeeee;
  border: none;
  border-radius: 38px;
}
@media (min-width: 768px) {
  .navbar-search.collapse {
    display: block !important;
    height: auto !important;
    overflow: visible !important;
    visibility: visible !important;
  }
}
@media (max-width: 767px) {
  .navbar-search {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .navbar-search .navbar-form {
    margin-top: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}
.container > .navbar-search,
.container-fluid > .navbar-search {
  margin-right: -15px;
  margin-left: -15px;
}
@media (min-width: 768px) {
  .container > .navbar-search,
  .container-fluid > .navbar-search {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-search-overlap {
  left: 0;
  right: 0;
  top: 0;
  position: absolute !important;
  background-color: #ffffff;
}
.navbar-search-overlap .form-group,
.navbar-search-overlap .form-control {
  display: block !important;
  margin: 0;
}
.navbar-search-overlap .form-control {
  background-color: transparent !important;
  height: 66px !important;
  border-radius: 0;
}
.navbar-search-overlap .form-control:focus {
  border-color: transparent;
}
.navbar-collapse-toolbar.in {
  overflow-y: visible;
}
.navbar-toolbar {
  float: left;
}
.navbar-toolbar:before,
.navbar-toolbar:after {
  content: " ";
  display: table;
}
.navbar-toolbar:after {
  clear: both;
}
.navbar-toolbar > li {
  float: left;
}
.navbar-toolbar > li:before,
.navbar-toolbar > li:after {
  content: " ";
  display: table;
}
.navbar-toolbar > li:after {
  clear: both;
}
.navbar-toolbar > li > a {
  padding-top: 22px;
  padding-bottom: 22px;
  line-height: 22px;
}
.navbar-toolbar .dropdown-menu {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
}
@media (max-width: 767px) {
  .navbar-toolbar .dropdown-menu:not(.dropdown-menu-media) {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .navbar-toolbar .open {
    position: static;
  }
  .navbar-toolbar .open .dropdown-menu {
    left: 0;
    right: 0;
    width: auto;
    margin-top: 0;
    float: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}
@media (max-width: 767px) and (max-device-width: 480px) and (orientation: landscape) {
  .navbar-toolbar .dropdown-menu:not(.dropdown-menu-media) {
    max-height: 200px;
  }
}
@media (max-width: 767px) {
  .navbar-toolbar-left {
    float: left !important;
  }
  .navbar-toolbar-right {
    float: right !important;
  }
}
.icon-fullscreen {
  font-family: "Material-Design-Iconic-Font";
  font-size: 24px;
}
.icon-fullscreen:before {
  content: '\f16d';
}
.icon-fullscreen.active:before {
  content: '\f16c';
}
.icon-menubar {
  font-family: "Material-Design-Iconic-Font";
}
.icon-menubar:before {
  content: '\f197';
}
.icon-menubar.active:before {
  content: '\f197';
}
.navbar-default .navbar-toolbar > li > a {
  color: #757575;
}
.navbar-default .navbar-toolbar > li > a:hover,
.navbar-default .navbar-toolbar > li > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, 0.3);
}
.navbar-default .navbar-toolbar > .active > a,
.navbar-default .navbar-toolbar > .active > a:hover,
.navbar-default .navbar-toolbar > .active > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, 0.6);
}
.navbar-default .navbar-toolbar > .disabled > a,
.navbar-default .navbar-toolbar > .disabled > a:hover,
.navbar-default .navbar-toolbar > .disabled > a:focus {
  color: #bdbdbd;
  background-color: transparent;
}
.navbar-default .navbar-toggle {
  color: #757575;
}
.navbar-default .navbar-toolbar > .open > a,
.navbar-default .navbar-toolbar > .open > a:hover,
.navbar-default .navbar-toolbar > .open > a:focus {
  color: #616161;
  background-color: rgba(238, 238, 238, 0.6);
}
.navbar-inverse .navbar-toolbar > li > a {
  color: #ffffff;
}
.navbar-inverse .navbar-toolbar > li > a:hover,
.navbar-inverse .navbar-toolbar > li > a:focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toolbar > .active > a,
.navbar-inverse .navbar-toolbar > .active > a:hover,
.navbar-inverse .navbar-toolbar > .active > a:focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-inverse .navbar-toolbar > .disabled > a,
.navbar-inverse .navbar-toolbar > .disabled > a:hover,
.navbar-inverse .navbar-toolbar > .disabled > a:focus {
  color: #ffffff;
  background-color: transparent;
}
.navbar-inverse .navbar-toggle {
  color: #ffffff;
}
.navbar-inverse .navbar-toolbar > .open > a,
.navbar-inverse .navbar-toolbar > .open > a:hover,
.navbar-inverse .navbar-toolbar > .open > a:focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.1);
}
.navbar-content {
  width: 320px;
  padding: 15px;
}
.navbar-content:after,
.navbar-content:before {
  line-height: 0;
  display: table;
  content: '';
}
.breadcrumb {
  margin-bottom: 10px;
}
.breadcrumb li + li:before {
  padding: 0 5px;
}
.breadcrumb li .icon {
  text-decoration: none;
}
.breadcrumb li .icon:before {
  margin-right: 10px;
}
.breadcrumb-arrow > li + li:before {
  content: "\00bb\00a0";
}
.pagination li > a,
.pagination li > span {
  padding: 7px 13px;
  transition: background 0.2s ease-out, border-color 0s ease-out, color 0.2s ease-out;
}
.pagination li > a:hover,
.pagination li > span:hover,
.pagination li > a:focus,
.pagination li > span:focus {
  transition: background 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out;
}
.pagination li .icon {
  margin-top: -1px;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #bdbdbd;
  cursor: not-allowed;
  background-color: transparent;
  border-color: #e0e0e0;
}
.pagination-gap > li > a {
  margin: 0 5px;
  border-radius: 5px;
}
.pagination-gap > li > a:hover {
  background-color: transparent;
  border-color: #c03b40;
}
.pagination-gap > li:first-child > a,
.pagination-gap > li:last-child > a {
  border-radius: 5px;
}
.pagination-no-border > li > a {
  border: none;
}
.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 9px 15px;
  font-size: 16px;
  line-height: 1.3333333;
}
.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 9px;
  font-size: 14px;
  line-height: 1.5;
}
.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.pager li > a,
.pager li > span {
  padding: 10px 20px;
  color: #757575;
  transition: all .2s ease;
}
.pager li > a:hover,
.pager li > a:focus {
  color: #c03b40;
}
.pager li .icon {
  margin-top: -1px;
}
.pager li > a:hover,
.pager li > a:focus {
  border-color: #c03b40;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  border-color: #e0e0e0;
}
.pager-round li > a,
.pager-round li > span {
  border-radius: 1000px;
}
.label {
  padding: 0.25em 0.6em 0.25em;
  font-weight: 300;
  border-radius: 0.3em;
}
.label.label-outline {
  color: #eeeeee;
  background-color: transparent;
  border-color: #eeeeee;
}
.label-outline {
  border: 1px solid transparent;
}
.label-round {
  border-radius: 1em;
}
.label-default {
  color: #757575;
  background-color: #e0e0e0;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #eeeeee;
}
.label-default.label-outline {
  color: #e0e0e0;
  background-color: transparent;
  border-color: #e0e0e0;
}
.label-default[href]:hover,
.label-default[href]:focus {
  color: #9e9e9e;
}
.label-default.label-outline {
  color: #757575;
}
.label-primary {
  background-color: #c03b40;
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #C75155;
}
.label-primary.label-outline {
  color: #c03b40;
  background-color: transparent;
  border-color: #c03b40;
}
.label-success {
  background-color: #4caf50;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #66bb6a;
}
.label-success.label-outline {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.label-info {
  background-color: #00bcd4;
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #26c6da;
}
.label-info.label-outline {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}
.label-warning {
  background-color: #ff9800;
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ffa726;
}
.label-warning.label-outline {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.label-danger {
  background-color: #c03b40;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #C75155;
}
.label-danger.label-outline {
  color: #c03b40;
  background-color: transparent;
  border-color: #c03b40;
}
.label-dark {
  background-color: #616161;
}
.label-dark[href]:hover,
.label-dark[href]:focus {
  background-color: #757575;
}
.label-dark.label-outline {
  color: #616161;
  background-color: transparent;
  border-color: #616161;
}
.label-lg {
  font-size: 16px;
}
.label-sm {
  padding: 0.1em 0.5em 0.1em;
  font-size: 10px;
}
.label-md {
  padding: 0.1em 0.5em 0.1em;
  font-size: 12px;
}
.badge {
  padding: 3px 6px;
}
.btn .badge {
  top: 0;
}
.badge.up {
  position: relative;
  top: -10px;
  margin: 0 -0.8em;
  border-radius: 15px;
}
.badge.badge-absolute {
  position: absolute;
  top: -8px;
  right: -10px;
  z-index: 5;
}
.badge-radius {
  border-radius: 3px;
}
.badge-primary {
  color: #ffffff;
  background-color: #c03b40;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #ffffff;
  background-color: #992f33;
}
.list-group-item.active > .badge-primary,
.nav-pills > .active > a > .badge-primary {
  color: #ffffff;
  background-color: #c03b40;
}
.badge-success {
  color: #ffffff;
  background-color: #4caf50;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #ffffff;
  background-color: #3d8b40;
}
.list-group-item.active > .badge-success,
.nav-pills > .active > a > .badge-success {
  color: #ffffff;
  background-color: #4caf50;
}
.badge-info {
  color: #ffffff;
  background-color: #00bcd4;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #ffffff;
  background-color: #008fa1;
}
.list-group-item.active > .badge-info,
.nav-pills > .active > a > .badge-info {
  color: #ffffff;
  background-color: #00bcd4;
}
.badge-warning {
  color: #ffffff;
  background-color: #ff9800;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #ffffff;
  background-color: #cc7a00;
}
.list-group-item.active > .badge-warning,
.nav-pills > .active > a > .badge-warning {
  color: #ffffff;
  background-color: #ff9800;
}
.badge-danger {
  color: #ffffff;
  background-color: #c03b40;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #ffffff;
  background-color: #992f33;
}
.list-group-item.active > .badge-danger,
.nav-pills > .active > a > .badge-danger {
  color: #ffffff;
  background-color: #c03b40;
}
.badge-dark {
  color: #ffffff;
  background-color: #616161;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #ffffff;
  background-color: #484848;
}
.list-group-item.active > .badge-dark,
.nav-pills > .active > a > .badge-dark {
  color: #ffffff;
  background-color: #616161;
}
.badge-lg {
  padding: 5px 9px 8px;
  font-size: 16px;
}
.badge-sm {
  padding: 2px 5px;
  font-size: 10px;
}
.jumbotron {
  padding: 0;
  border-radius: 3px;
}
.jumbotron > .jumbotron-photo img {
  width: 100%;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.jumbotron-contents {
  padding: 20px;
}
.jumbotron .carousel,
.jumbotron .carousel-inner,
.jumbotron .carousel-inner > .item.active img {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.jumbotron .carousel-inner > .item > a > img,
.jumbotron .carousel-inner > .item > img {
  width: 100%;
}
.jumbotron h1,
.jumbotron .h1 {
  font-size: 28px;
}
.jumbotron h2,
.jumbotron .h2 {
  font-size: 24px;
}
@media screen and (min-width: 768px) {
  .jumbotron,
  .container .jumbotron {
    padding: 0;
  }
  .jumbotron h1,
  .jumbotron .h1 {
    font-size: 28px;
  }
}
.thumbnail {
  padding: 0;
  border: none;
  transition: all 0.25s ease-in-out;
}
.thumbnail .caption {
  position: relative;
  display: block;
  padding-right: 0;
  padding-left: 0;
}
.alert {
  padding-right: 20px;
  padding-left: 20px;
}
.alert ul {
  padding-left: 13px;
}
.alert ul li {
  padding-left: 7px;
}
.panel > .alert {
  margin: 0;
}
.alert-alt {
  color: #757575;
  background-color: rgba(238, 238, 238, 0.8);
  border: none;
  border-left: 3px solid transparent;
}
.alert-alt a,
.alert-alt .alert-link {
  text-decoration: none;
}
.alert-dismissible {
  padding-right: 40px;
}
.alert-dismissible .close {
  top: 0;
  text-decoration: none;
  opacity: 0.6;
}
.alert-dismissible .close:hover,
.alert-dismissible .close:focus {
  opacity: 1;
}
.alert-dismissible.alert-alt .close {
  color: #9e9e9e;
  opacity: 0.6;
}
.alert-dismissible.alert-alt .close:hover,
.alert-dismissible.alert-alt .close:focus {
  color: #9e9e9e;
  opacity: 1;
}
.alert-icon {
  position: relative;
  padding-left: 45px;
}
.alert-icon > .icon {
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: 16px;
  width: 1em;
  text-align: center;
}
.alert-avatar {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 75px;
}
.alert-avatar > .avatar {
  position: absolute;
  top: 12px;
  left: 20px;
}
.page-alert .alert-wrap {
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  transition: max-height 0.7s linear 0s;
}
.page-alert .alert-wrap.in {
  max-height: 500px;
  transition: max-height 1s linear 0s;
}
.page-alert .alert-wrap .alert {
  margin: 0;
  text-align: left;
  border-radius: 0;
}
.alert-primary {
  background-color: rgba(255, 205, 210, 0.8);
  border-color: #ffcdd2;
  color: #c03b40;
}
.alert-primary hr {
  border-top-color: #ffb3bb;
}
.alert-primary .alert-link {
  color: #992f33;
}
.alert-primary .close {
  color: #c03b40;
}
.alert-primary .close:hover,
.alert-primary .close:focus {
  color: #c03b40;
}
.alert-primary .alert-link {
  color: #BA262B;
}
.alert-alt.alert-primary {
  border-color: #c03b40;
}
.alert-alt.alert-primary a,
.alert-alt.alert-primary .alert-link {
  color: #c03b40;
}
.alert-success .alert-link {
  color: #43a047;
}
.alert-alt.alert-success {
  border-color: #4caf50;
}
.alert-alt.alert-success a,
.alert-alt.alert-success .alert-link {
  color: #4caf50;
}
.alert-info .alert-link {
  color: #00acc1;
}
.alert-alt.alert-info {
  border-color: #00bcd4;
}
.alert-alt.alert-info a,
.alert-alt.alert-info .alert-link {
  color: #00bcd4;
}
.alert-warning .alert-link {
  color: #fb8c00;
}
.alert-alt.alert-warning {
  border-color: #ff9800;
}
.alert-alt.alert-warning a,
.alert-alt.alert-warning .alert-link {
  color: #ff9800;
}
.alert-danger .alert-link {
  color: #BA262B;
}
.alert-alt.alert-danger {
  border-color: #c03b40;
}
.alert-alt.alert-danger a,
.alert-alt.alert-danger .alert-link {
  color: #c03b40;
}
.alert-social {
  position: relative;
  padding-left: 65px;
}
.alert-social > .icon {
  position: absolute;
  top: 12px;
  left: 20px;
  bottom: 0;
  font-size: 30px;
  width: 1em;
  text-align: center;
}
.alert-facebook {
  background-color: #3B5998;
  border-color: #3B5998;
  color: #ffffff;
}
.alert-facebook hr {
  border-top-color: #344e86;
}
.alert-facebook .alert-link {
  color: #e6e6e6;
}
.alert-facebook .close {
  color: #ffffff;
}
.alert-facebook .close:hover,
.alert-facebook .close:focus {
  color: #ffffff;
}
.alert-facebook .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-twitter {
  background-color: #55ACEE;
  border-color: #55ACEE;
  color: #ffffff;
}
.alert-twitter hr {
  border-top-color: #3ea1ec;
}
.alert-twitter .alert-link {
  color: #e6e6e6;
}
.alert-twitter .close {
  color: #ffffff;
}
.alert-twitter .close:hover,
.alert-twitter .close:focus {
  color: #ffffff;
}
.alert-twitter .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-google-plus {
  background-color: #DD4B39;
  border-color: #DD4B39;
  color: #ffffff;
}
.alert-google-plus hr {
  border-top-color: #d73925;
}
.alert-google-plus .alert-link {
  color: #e6e6e6;
}
.alert-google-plus .close {
  color: #ffffff;
}
.alert-google-plus .close:hover,
.alert-google-plus .close:focus {
  color: #ffffff;
}
.alert-google-plus .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-linkedin {
  background-color: #0976B4;
  border-color: #0976B4;
  color: #ffffff;
}
.alert-linkedin hr {
  border-top-color: #08669c;
}
.alert-linkedin .alert-link {
  color: #e6e6e6;
}
.alert-linkedin .close {
  color: #ffffff;
}
.alert-linkedin .close:hover,
.alert-linkedin .close:focus {
  color: #ffffff;
}
.alert-linkedin .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-flickr {
  background-color: #FF0084;
  border-color: #FF0084;
  color: #ffffff;
}
.alert-flickr hr {
  border-top-color: #e60077;
}
.alert-flickr .alert-link {
  color: #e6e6e6;
}
.alert-flickr .close {
  color: #ffffff;
}
.alert-flickr .close:hover,
.alert-flickr .close:focus {
  color: #ffffff;
}
.alert-flickr .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-tumblr {
  background-color: #35465C;
  border-color: #35465C;
  color: #ffffff;
}
.alert-tumblr hr {
  border-top-color: #2c3a4c;
}
.alert-tumblr .alert-link {
  color: #e6e6e6;
}
.alert-tumblr .close {
  color: #ffffff;
}
.alert-tumblr .close:hover,
.alert-tumblr .close:focus {
  color: #ffffff;
}
.alert-tumblr .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-github {
  background-color: #4183c4;
  border-color: #4183c4;
  color: #ffffff;
}
.alert-github hr {
  border-top-color: #3876b4;
}
.alert-github .alert-link {
  color: #e6e6e6;
}
.alert-github .close {
  color: #ffffff;
}
.alert-github .close:hover,
.alert-github .close:focus {
  color: #ffffff;
}
.alert-github .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-dribbble {
  background-color: #C32361;
  border-color: #C32361;
  color: #ffffff;
}
.alert-dribbble hr {
  border-top-color: #ad1f56;
}
.alert-dribbble .alert-link {
  color: #e6e6e6;
}
.alert-dribbble .close {
  color: #ffffff;
}
.alert-dribbble .close:hover,
.alert-dribbble .close:focus {
  color: #ffffff;
}
.alert-dribbble .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert-youtube {
  background-color: #B31217;
  border-color: #B31217;
  color: #ffffff;
}
.alert-youtube hr {
  border-top-color: #9c1014;
}
.alert-youtube .alert-link {
  color: #e6e6e6;
}
.alert-youtube .close {
  color: #ffffff;
}
.alert-youtube .close:hover,
.alert-youtube .close:focus {
  color: #ffffff;
}
.alert-youtube .alert-link {
  color: #ffffff;
  font-weight: 500;
}
.alert.dark .alert-link {
  color: #ffffff !important;
  font-weight: 500;
}
.alert.dark .alert-left-border {
  border: none;
  border-left: 3px solid transparent;
}
.alert.dark.alert-dismissible.alert-alt .close {
  color: #ffffff;
}
.alert.dark.alert-dismissible.alert-alt .close:hover,
.alert.dark.alert-dismissible.alert-alt .close:focus {
  color: #ffffff;
}
.alert.dark.alert-primary {
  background-color: #c03b40;
  border-color: #c03b40;
  color: #ffffff;
}
.alert.dark.alert-primary hr {
  border-top-color: #ac3539;
}
.alert.dark.alert-primary .alert-link {
  color: #e6e6e6;
}
.alert.dark.alert-primary .close {
  color: #ffffff;
}
.alert.dark.alert-primary .close:hover,
.alert.dark.alert-primary .close:focus {
  color: #ffffff;
}
.alert-alt.alert.dark.alert-primary {
  border-color: #7a191c;
}
.alert-alt.alert.dark.alert-primary a,
.alert-alt.alert.dark.alert-primary .alert-link {
  color: #ffffff;
}
.alert.dark.alert-success {
  background-color: #4caf50;
  border-color: #4caf50;
  color: #ffffff;
}
.alert.dark.alert-success hr {
  border-top-color: #449d48;
}
.alert.dark.alert-success .alert-link {
  color: #e6e6e6;
}
.alert.dark.alert-success .close {
  color: #ffffff;
}
.alert.dark.alert-success .close:hover,
.alert.dark.alert-success .close:focus {
  color: #ffffff;
}
.alert-alt.alert.dark.alert-success {
  border-color: #2c6a2f;
}
.alert-alt.alert.dark.alert-success a,
.alert-alt.alert.dark.alert-success .alert-link {
  color: #ffffff;
}
.alert.dark.alert-info {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #ffffff;
}
.alert.dark.alert-info hr {
  border-top-color: #00a5bb;
}
.alert.dark.alert-info .alert-link {
  color: #e6e6e6;
}
.alert.dark.alert-info .close {
  color: #ffffff;
}
.alert.dark.alert-info .close:hover,
.alert.dark.alert-info .close:focus {
  color: #ffffff;
}
.alert-alt.alert.dark.alert-info {
  border-color: #006875;
}
.alert-alt.alert.dark.alert-info a,
.alert-alt.alert.dark.alert-info .alert-link {
  color: #ffffff;
}
.alert.dark.alert-warning {
  background-color: #ff9800;
  border-color: #ff9800;
  color: #ffffff;
}
.alert.dark.alert-warning hr {
  border-top-color: #e68900;
}
.alert.dark.alert-warning .alert-link {
  color: #e6e6e6;
}
.alert.dark.alert-warning .close {
  color: #ffffff;
}
.alert.dark.alert-warning .close:hover,
.alert.dark.alert-warning .close:focus {
  color: #ffffff;
}
.alert-alt.alert.dark.alert-warning {
  border-color: #af6100;
}
.alert-alt.alert.dark.alert-warning a,
.alert-alt.alert.dark.alert-warning .alert-link {
  color: #ffffff;
}
.alert.dark.alert-danger {
  background-color: #c03b40;
  border-color: #c03b40;
  color: #ffffff;
}
.alert.dark.alert-danger hr {
  border-top-color: #ac3539;
}
.alert.dark.alert-danger .alert-link {
  color: #e6e6e6;
}
.alert.dark.alert-danger .close {
  color: #ffffff;
}
.alert.dark.alert-danger .close:hover,
.alert.dark.alert-danger .close:focus {
  color: #ffffff;
}
.alert-alt.alert.dark.alert-danger {
  border-color: #7a191c;
}
.alert-alt.alert.dark.alert-danger a,
.alert-alt.alert.dark.alert-danger .alert-link {
  color: #ffffff;
}
.progress {
  height: 15px;
  box-shadow: none;
}
.progress-bar {
  line-height: 15px;
  box-shadow: none;
}
.progress-square {
  border-radius: 0;
}
.progress-circle {
  border-radius: 1000px;
}
.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 15px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 30px;
}
.progress-vertical .progress-bar {
  width: 100%;
}
.progress-bar-indicating.active {
  position: relative;
  animation: none;
}
.progress-bar-indicating.active:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  background-color: #ffffff;
  border-radius: inherit;
  opacity: 0;
  animation: progress-active 3s ease 0s infinite;
}
.progress-vertical .progress-bar-indicating.active:before {
  animation-name: progress-vertical-active;
}
.progress-skill {
  position: relative;
}
.progress-skill .progress-bar > span {
  position: absolute;
  top: 0;
  right: 10px;
  color: #616161;
}
.progress-lg {
  height: 22px;
}
.progress-lg.progress-vertical {
  width: 25px;
}
.progress-lg .progress-bar {
  line-height: 22px;
}
.progress-sm {
  height: 10px;
}
.progress-sm.progress-vertical {
  width: 10px;
}
.progress-sm .progress-bar {
  line-height: 10px;
}
.progress-xs {
  height: 4px;
  border-radius: 1px;
}
.progress-xs.progress-vertical {
  width: 4px;
}
.progress-xs .progress-bar {
  line-height: 4px;
}
.contextual-progress {
  margin: 20px 0;
}
.contextual-progress .progress-title {
  float: left;
}
.contextual-progress .progress-label {
  position: relative;
  float: right;
}
.contextual-progress .progress {
  height: 2px;
  margin: 5px 0;
}
@-webkit-keyframes progress-active {
  0% {
    width: 0;
    opacity: .4;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes progress-active {
  0% {
    width: 0;
    opacity: .4;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@-webkit-keyframes progress-vertical-active {
  0% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 175px;
    opacity: .4;
  }
}
@keyframes progress-vertical-active {
  0% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 175px;
    opacity: .4;
  }
}
.media-object {
  width: 120px;
}
.media-left,
.media > .pull-left {
  padding-right: 20px;
}
.media-right,
.media > .pull-right {
  padding-left: 20px;
}
.media-body {
  overflow: auto;
}
.media .media {
  padding-bottom: 0;
  border-bottom: none;
}
.media-meta {
  color: #616161;
  font-size: 12px;
  margin-bottom: 3px;
}
.media-lg .media-object {
  width: 160px;
}
.media-lg .media {
  margin-left: -110px;
}
.media-sm .media-object {
  width: 80px;
}
.media-sm .media {
  margin-left: -70px;
}
.media-xs .media-object {
  width: 60px;
}
.media-xs .media {
  margin-left: -60px;
}
@media screen and (min-width: 768px) {
  .media-body {
    overflow: hidden;
  }
  .media .media {
    margin-left: 0;
  }
}
.list-group .media {
  padding: 2px 0;
  border-bottom: 0;
}
.list-group .media .pull-left,
.list-group .media .media-left {
  padding-right: 20px;
}
.list-group .media .pull-right,
.list-group .media .media-right {
  padding-left: 20px;
}
.list-group .media .pull-right .status {
  margin-top: 15px;
  margin-right: 5px;
}
.list-group .media .media-heading {
  font-size: 14px;
}
.list-group-full > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
a.list-group-item {
  border-radius: 3px;
}
a.list-group-item.disabled,
a.list-group-item.disabled:hover,
a.list-group-item.disabled:focus {
  color: #bdbdbd;
  background-color: #eeeeee;
}
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  color: #ffffff;
  background-color: #c03b40;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.list-group-item .icon {
  margin-right: 10px;
}
.list-group.bg-inherit {
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.list-group.bg-inherit .list-group-item {
  background-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.075);
}
.list-group.bg-inherit .list-group-item:last-child {
  border-bottom-color: transparent;
}
.list-group.bg-inherit .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.075);
  border-color: transparent;
}
.list-group-bordered {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.list-group-bordered .list-group-item {
  border-color: #e0e0e0;
}
.list-group-bordered .list-group-item.active,
.list-group-bordered .list-group-item.active:hover,
.list-group-bordered .list-group-item.active:focus {
  color: #ffffff;
  background-color: #a72226;
  border-color: #a72226;
}
.list-group-dividered .list-group-item {
  border-top-color: #e0e0e0;
}
.list-group-dividered .list-group-item.active:hover {
  border-top-color: #e0e0e0;
}
.list-group-dividered .list-group-item:last-child {
  border-bottom-color: #e0e0e0;
}
.list-group-dividered .list-group-item:first-child {
  border-top-color: transparent;
}
.list-group-dividered .list-group-item:first-child.active:hover {
  border-top-color: transparent;
}
.list-group-gap .list-group-item {
  margin-bottom: 2px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.list-group-full .list-group-item {
  padding-right: 0;
  padding-left: 0;
}
.list-group-item-dark {
  color: #ffffff;
  background-color: #616161;
}
a.list-group-item-dark,
button.list-group-item-dark {
  color: #ffffff;
}
a.list-group-item-dark .list-group-item-heading,
button.list-group-item-dark .list-group-item-heading {
  color: inherit;
}
a.list-group-item-dark:hover,
button.list-group-item-dark:hover,
a.list-group-item-dark:focus,
button.list-group-item-dark:focus {
  color: #ffffff;
  background-color: #545454;
}
a.list-group-item-dark.active,
button.list-group-item-dark.active,
a.list-group-item-dark.active:hover,
button.list-group-item-dark.active:hover,
a.list-group-item-dark.active:focus,
button.list-group-item-dark.active:focus {
  color: #fff;
  background-color: #ffffff;
  border-color: #ffffff;
}
.panel {
  position: relative;
  border-width: 0;
  margin-bottom: 30px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.panel > .nav-tabs-vertical .nav-tabs {
  margin-left: -1px;
}
.panel > .nav-tabs-vertical .nav-tabs > li > a {
  border-left: none;
  border-radius: 0;
}
.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse {
  margin-right: -1px;
}
.panel > .nav-tabs-vertical .nav-tabs.nav-tabs-reverse > li > a {
  border-right: none;
  border-radius: 0;
}
.panel:hover .panel-actions .show-on-hover {
  display: inline-block;
}
.panel .panel-actions .show-on-hover {
  display: none;
}
.panel.is-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  border-radius: 0;
}
.panel.is-fullscreen .panel-loading {
  border-radius: 0;
}
.panel.is-fullscreen .panel-actions [data-toggle=collapse] {
  display: none;
}
.panel.is-close {
  display: none;
}
.panel.is-collapse .panel-body {
  display: none;
  height: 0;
}
.panel > .alert {
  padding-left: 30px;
  padding-right: 30px;
}
.panel > .alert-dismissible {
  padding-right: 50px;
}
@media screen and (max-width: 480px) {
  .panel > .alert {
    padding-left: 20px;
    padding-right: 20px;
  }
  .panel > .alert-dismissible {
    padding-right: 40px;
  }
}
.panel > .table > tr > td:first-child,
.panel > .table-responsive > .table > tr > td:first-child,
.panel > .table > thead > tr > td:first-child,
.panel > .table-responsive > .table > thead > tr > td:first-child,
.panel > .table > tbody > tr > td:first-child,
.panel > .table-responsive > .table > tbody > tr > td:first-child,
.panel > .table > tfoot > tr > td:first-child,
.panel > .table-responsive > .table > tfoot > tr > td:first-child,
.panel > .table > tr > th:first-child,
.panel > .table-responsive > .table > tr > th:first-child,
.panel > .table > thead > tr > th:first-child,
.panel > .table-responsive > .table > thead > tr > th:first-child,
.panel > .table > tbody > tr > th:first-child,
.panel > .table-responsive > .table > tbody > tr > th:first-child,
.panel > .table > tfoot > tr > th:first-child,
.panel > .table-responsive > .table > tfoot > tr > th:first-child {
  padding-left: 30px;
}
@media screen and (max-width: 480px) {
  .panel > .table > tr > td:first-child,
  .panel > .table-responsive > .table > tr > td:first-child,
  .panel > .table > thead > tr > td:first-child,
  .panel > .table-responsive > .table > thead > tr > td:first-child,
  .panel > .table > tbody > tr > td:first-child,
  .panel > .table-responsive > .table > tbody > tr > td:first-child,
  .panel > .table > tfoot > tr > td:first-child,
  .panel > .table-responsive > .table > tfoot > tr > td:first-child,
  .panel > .table > tr > th:first-child,
  .panel > .table-responsive > .table > tr > th:first-child,
  .panel > .table > thead > tr > th:first-child,
  .panel > .table-responsive > .table > thead > tr > th:first-child,
  .panel > .table > tbody > tr > th:first-child,
  .panel > .table-responsive > .table > tbody > tr > th:first-child,
  .panel > .table > tfoot > tr > th:first-child,
  .panel > .table-responsive > .table > tfoot > tr > th:first-child {
    padding-left: 20px;
  }
}
.panel > .table > tr > td:last-child,
.panel > .table-responsive > .table > tr > td:last-child,
.panel > .table > thead > tr > td:last-child,
.panel > .table-responsive > .table > thead > tr > td:last-child,
.panel > .table > tbody > tr > td:last-child,
.panel > .table-responsive > .table > tbody > tr > td:last-child,
.panel > .table > tfoot > tr > td:last-child,
.panel > .table-responsive > .table > tfoot > tr > td:last-child,
.panel > .table > tr > th:last-child,
.panel > .table-responsive > .table > tr > th:last-child,
.panel > .table > thead > tr > th:last-child,
.panel > .table-responsive > .table > thead > tr > th:last-child,
.panel > .table > tbody > tr > th:last-child,
.panel > .table-responsive > .table > tbody > tr > th:last-child,
.panel > .table > tfoot > tr > th:last-child,
.panel > .table-responsive > .table > tfoot > tr > th:last-child {
  padding-right: 30px;
}
@media screen and (max-width: 480px) {
  .panel > .table > tr > td:last-child,
  .panel > .table-responsive > .table > tr > td:last-child,
  .panel > .table > thead > tr > td:last-child,
  .panel > .table-responsive > .table > thead > tr > td:last-child,
  .panel > .table > tbody > tr > td:last-child,
  .panel > .table-responsive > .table > tbody > tr > td:last-child,
  .panel > .table > tfoot > tr > td:last-child,
  .panel > .table-responsive > .table > tfoot > tr > td:last-child,
  .panel > .table > tr > th:last-child,
  .panel > .table-responsive > .table > tr > th:last-child,
  .panel > .table > thead > tr > th:last-child,
  .panel > .table-responsive > .table > thead > tr > th:last-child,
  .panel > .table > tbody > tr > th:last-child,
  .panel > .table-responsive > .table > tbody > tr > th:last-child,
  .panel > .table > tfoot > tr > th:last-child,
  .panel > .table-responsive > .table > tfoot > tr > th:last-child {
    padding-right: 20px;
  }
}
.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 1px solid #e0e0e0;
}
.panel > .list-group > .list-group-item {
  padding-right: 30px;
  padding-left: 30px;
}
@media screen and (max-width: 480px) {
  .panel > .list-group > .list-group-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.panel-content > .row {
  padding-left: 30px;
  padding-right: 30px;
}
.panel-content > .row > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}
.panel-heading {
  position: relative;
  padding: 0;
  border-bottom: 1px solid transparent;
}
.panel-heading + .alert {
  border-radius: 0;
}
.panel-heading > .nav-tabs {
  border-bottom: none;
}
.panel-heading + .nav-tabs {
  margin-top: -10px;
}
.panel-body {
  position: relative;
}
.panel-heading + .panel-body {
  padding-top: 0;
}
.panel-body h1:first-child,
.panel-body h2:first-child,
.panel-body h3:first-child,
.panel-body h4:first-child,
.panel-body h5:first-child,
.panel-body h6:first-child,
.panel-body .h1:first-child,
.panel-body .h2:first-child,
.panel-body .h3:first-child,
.panel-body .h4:first-child,
.panel-body .h5:first-child,
.panel-body .h6:first-child {
  margin-top: 0;
}
.panel-body > *:last-child {
  margin-bottom: 0;
}
.panel-body > .list-group-dividered:only-child > .list-group-item:last-child {
  border-bottom-color: transparent;
}
.panel-footer {
  border-top: 1px solid transparent;
}
.table + .panel-footer {
  padding-top: 15px;
  border-color: #e0e0e0;
}
.panel-title {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #424242;
}
.panel-title > .icon {
  margin-right: 10px;
}
.panel-title > .label {
  margin-left: 10px;
}
.panel-title small {
  color: #757575;
}
.panel-desc {
  display: block;
  padding: 5px 0 0;
  margin: 0;
  font-size: 14px;
  color: #757575;
}
.panel-actions {
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -o-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  margin: auto;
  z-index: 1;
}
@media screen and (max-width: 480px) {
  .panel-actions {
    right: 20px;
  }
}
ul.panel-actions {
  list-style: none;
}
ul.panel-actions > li {
  display: inline-block;
  margin-left: 8px;
}
ul.panel-actions > li:first-child {
  margin-left: 0;
}
.panel-actions a {
  color: inherit;
}
.panel-actions a.dropdown-toggle {
  text-decoration: none;
}
.panel-actions .dropdown {
  display: inline-block;
}
.panel-actions .dropdown-toggle {
  display: inline-block;
}
.panel-actions .panel-action {
  display: inline-block;
  padding: 8px 10px;
  color: #9e9e9e;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
}
.panel-actions .panel-action:hover {
  color: #616161;
}
.panel-actions .panel-action:active {
  color: #616161;
}
.panel-actions .panel-action:focus {
  outline: none;
}
.panel-actions .progress {
  width: 100px;
  margin: 0;
}
.panel-actions .pagination {
  margin: 0;
}
.panel-toolbar {
  padding: 5px 15px;
  margin: 0;
  background-color: transparent;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}
.panel-bordered .panel-toolbar {
  border-top-color: transparent;
}
.panel-toolbar .btn {
  color: #9e9e9e;
  padding: 5px 10px;
}
.panel-toolbar .btn.icon {
  width: 1em;
  text-align: center;
}
.panel-toolbar .btn:hover,
.panel-toolbar .btn:active,
.panel-toolbar .btn.active {
  color: #757575;
}
.panel-loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  opacity: .6;
}
.panel-loading .loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}
.panel > *:not(.panel-loading):not(.collapsing) {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}
.panel.is-loading > *:not(.panel-loading) {
  opacity: .3;
}
.panel.is-loading .panel-loading {
  display: block;
  opacity: 1;
}
.panel-footer-chart {
  padding: 0;
}
.panel-control {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.panel-body.scrollable-vertical {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.panel-body.scrollable-vertical > .scrollable-container > .scrollable-content {
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (max-width: 480px) {
  .panel-body.scrollable-vertical > .scrollable-container > .scrollable-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.panel-body.scrollable-vertical > .scrollable-bar {
  margin-top: 0;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  -ms-transform: translateX(-26px);
  -webkit-transform: translateX(-26px);
  transform: translateX(-26px);
}
.panel-bordered > .panel-body.scrollable-vertical > .scrollable-bar {
  margin-bottom: 30px;
  height: calc(100% - 60px);
}
.panel-body.scrollable-horizontal {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.panel-body.scrollable-horizontal > .scrollable-container > .scrollable-content {
  padding-top: 0;
  padding-bottom: 30px;
}
.panel-bordered > .panel-body.scrollable-horizontal > .scrollable-container > .scrollable-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
.panel-body.scrollable-horizontal > .scrollable-bar {
  margin-left: 0;
  margin-right: 30px;
  width: calc(100% - 60px);
  -ms-transform: translateY(-26px);
  -webkit-transform: translateY(-26px);
  transform: translateY(-26px);
}
@media screen and (max-width: 480px) {
  .panel-body.scrollable-horizontal > .scrollable-bar {
    margin-right: 20px;
    width: calc(100% - 40px);
  }
}
.panel-bordered > .panel-body.scrollable-horizontal > .scrollable-bar {
  -ms-transform: translateY(-26px);
  -webkit-transform: translateY(-26px);
  transform: translateY(-26px);
}
.panel-bordered > .panel-heading {
  border-bottom: 1px solid #e0e0e0;
}
.panel-bordered > .panel-heading > .panel-title {
  padding-bottom: 20px;
}
.panel-bordered > .panel-footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}
.panel-bordered > .panel-body {
  padding-top: 30px;
}
.panel-bordered > .table > tbody:first-child > tr:first-child th,
.panel-bordered > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}
.panel.is-dragging {
  opacity: 0.8;
}
.panel.is-dragging {
  cursor: move;
}
.panel.panel-transparent {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.panel.panel-transparent > .panel-heading,
.panel.panel-transparent > .panel-footer {
  border-color: transparent;
}
.panel-dark {
  border-color: #616161;
}
.panel-dark > .panel-heading {
  color: #ffffff;
  background-color: #616161;
  border-color: #616161;
}
.panel-dark > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #616161;
}
.panel-dark > .panel-heading .badge {
  color: #616161;
  background-color: #ffffff;
}
.panel-dark > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #616161;
}
.panel-primary,
.panel-info,
.panel-success,
.panel-warning,
.panel-danger,
.panel-dark {
  border: none;
}
.panel-primary .panel-heading,
.panel-info .panel-heading,
.panel-success .panel-heading,
.panel-warning .panel-heading,
.panel-danger .panel-heading,
.panel-dark .panel-heading {
  border: none;
}
.panel-primary .panel-title,
.panel-info .panel-title,
.panel-success .panel-title,
.panel-warning .panel-title,
.panel-danger .panel-title,
.panel-dark .panel-title {
  color: #ffffff;
}
.panel-primary .panel-action,
.panel-info .panel-action,
.panel-success .panel-action,
.panel-warning .panel-action,
.panel-danger .panel-action,
.panel-dark .panel-action {
  color: #ffffff;
}
@media screen and (max-width: 480px) {
  .panel-actions {
    right: 20px;
  }
  .panel-actions .progress {
    min-width: 80px;
  }
  .panel-actions .show-on-hover {
    display: none;
  }
  .panel-title,
  .panel-body,
  .panel-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.well {
  padding: 20px;
}
.well-lg {
  padding: 24px;
}
.well-sm {
  padding: 12px;
  border-radius: 3px;
}
.well {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.02);
}
.well-primary {
  color: #ffffff;
  background-color: #c03b40;
}
.well-success {
  color: #ffffff;
  background-color: #4caf50;
}
.well-info {
  color: #ffffff;
  background-color: #00bcd4;
}
.well-warning {
  color: #ffffff;
  background-color: #ff9800;
}
.well-danger {
  color: #ffffff;
  background-color: #c03b40;
}
.close.icon {
  font-size: inherit;
}
body {
  font-weight: 300;
}
th {
  font-weight: 400;
}
b,
strong {
  font-weight: 500;
}
optgroup {
  font-weight: 500;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: 300;
}
.lead {
  font-weight: 100;
}
dt {
  font-weight: 500;
}
kbd kbd {
  font-weight: 500;
}
label {
  font-weight: 300;
}
.radio label,
.checkbox label {
  font-weight: 300;
}
.radio-inline,
.checkbox-inline {
  font-weight: 300;
}
.btn-link {
  font-weight: 300;
}
.dropdown-menu > li > a {
  font-weight: 300;
}
.input-group-addon {
  font-weight: 300;
}
.label {
  font-weight: 500;
}
.popover {
  font-weight: 300;
}
.tooltip {
  font-weight: 300;
}
.modal-content {
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}
.modal-header {
  padding: 15px 20px;
  border-bottom: none;
}
.modal-header .close {
  margin-top: 1px;
}
.modal-body {
  padding: 20px;
}
.modal-footer {
  padding: 6px 20px 20px;
  border-top: none;
}
.modal-top {
  margin: 0 auto;
}
.modal-center {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  margin: 0 auto;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}
.modal-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  margin: 0 auto;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}
.modal-sidebar {
  position: absolute;
  right: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  margin: 0;
  background-color: #ffffff;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
}
.modal-sidebar .modal-content {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}
.modal-sidebar .modal-header {
  border-bottom: none;
}
.modal-sidebar .modal-footer {
  border-top: none;
}
.modal-sidebar button.close {
  position: fixed;
  top: 20px;
  right: 20px;
}
.modal.fade .modal-dialog.modal-sidebar {
  -webkit-transform: translate(25%, 0px);
  -ms-transform: translate(25%, 0px);
  -o-transform: translate(25%, 0px);
  transform: translate(25%, 0px);
}
.modal.in .modal-dialog.modal-sidebar {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.modal-fill-in {
  background-color: transparent;
}
.modal-fill-in.in {
  background-color: rgba(255, 255, 255, 0.95);
  opacity: 1;
}
.modal-fill-in .modal-dialog {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 768px) {
  .modal-fill-in .modal-dialog > * {
    width: 600px;
  }
  .modal-fill-in .modal-dialog.modal-sm > * {
    width: 300px;
  }
  .modal-fill-in .modal-dialog button.close {
    position: fixed;
    top: 20px;
    right: 20px;
    opacity: 0.5;
    filter: alpha(opacity=50);
    z-index: 1;
    -webkit-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
@media (min-width: 992px) {
  .modal-fill-in .modal-dialog.modal-lg > * {
    width: 900px;
  }
}
.modal-fill-in .modal-content {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}
.modal-fill-in .modal-header {
  border-bottom: none;
}
.modal-fill-in .modal-footer {
  border-top: none;
}
.modal-primary .modal-header {
  background-color: #c03b40;
  border-radius: 4px 4px 0 0;
}
.modal-primary .modal-header * {
  color: #ffffff;
}
.modal-primary .modal-header .close {
  opacity: .6;
}
.modal-success .modal-header {
  background-color: #4caf50;
  border-radius: 4px 4px 0 0;
}
.modal-success .modal-header * {
  color: #ffffff;
}
.modal-success .modal-header .close {
  opacity: .6;
}
.modal-info .modal-header {
  background-color: #00bcd4;
  border-radius: 4px 4px 0 0;
}
.modal-info .modal-header * {
  color: #ffffff;
}
.modal-info .modal-header .close {
  opacity: .6;
}
.modal-warning .modal-header {
  background-color: #ff9800;
  border-radius: 4px 4px 0 0;
}
.modal-warning .modal-header * {
  color: #ffffff;
}
.modal-warning .modal-header .close {
  opacity: .6;
}
.modal-danger .modal-header {
  background-color: #c03b40;
  border-radius: 4px 4px 0 0;
}
.modal-danger .modal-header * {
  color: #ffffff;
}
.modal-danger .modal-header .close {
  opacity: .6;
}
.modal.modal-fade-in-scale-up .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  -o-transform: scale(0.7);
  transform: scale(0.7);
  transition: all .3s ease 0s;
}
.modal.modal-fade-in-scale-up.in .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.modal.modal-slide-in-right .modal-dialog {
  opacity: 0;
  -webkit-transform: translate(20%, 0%);
  -ms-transform: translate(20%, 0%);
  -o-transform: translate(20%, 0%);
  transform: translate(20%, 0%);
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9 0s);
}
.modal.modal-slide-in-right.in .modal-dialog {
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.modal.modal-slide-from-bottom .modal-dialog {
  opacity: 0;
  -webkit-transform: translate(0%, 20%);
  -ms-transform: translate(0%, 20%);
  -o-transform: translate(0%, 20%);
  transform: translate(0%, 20%);
  transition: all .3s ease 0s;
}
.modal.modal-slide-from-bottom.in .modal-dialog {
  opacity: 1;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}
.modal.modal-newspaper .modal-dialog {
  opacity: 0;
  -ms-transform: scale(0) rotate(720deg);
  -webkit-transform: scale(0) rotate(720deg);
  transform: scale(0) rotate(720deg);
  transition: all 0.5s ease 0s;
}
.modal.modal-newspaper.in .modal-dialog {
  opacity: 1;
  -ms-transform: scale(1) rotate(0deg);
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.modal.modal-fall {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-fall .modal-dialog {
  opacity: 0;
  -ms-transform: translateZ(600px) rotateX(20deg);
  -webkit-transform: translateZ(600px) rotateX(20deg);
  transform: translateZ(600px) rotateX(20deg);
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-fall.in .modal-dialog {
  opacity: 1;
  -ms-transform: translateZ(0px) rotateX(0deg);
  -webkit-transform: translateZ(0px) rotateX(0deg);
  transform: translateZ(0px) rotateX(0deg);
  transition: all 0.3s ease-in 0s;
}
.modal.modal-side-fall {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-side-fall .modal-dialog {
  -ms-transform: translate(30%) translateZ(600px) rotate(10deg);
  -webkit-transform: translate(30%) translateZ(600px) rotate(10deg);
  transform: translate(30%) translateZ(600px) rotate(10deg);
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.modal.modal-side-fall.in .modal-dialog {
  -ms-transform: translate(0%) translateZ(0px) rotate(0deg);
  -webkit-transform: translate(0%) translateZ(0px) rotate(0deg);
  transform: translate(0%) translateZ(0px) rotate(0deg);
  transition: all 0.3s ease-in 0s;
}
.modal.modal-3d-flip-horizontal {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-flip-horizontal .modal-dialog {
  -webkit-transform: rotateY(-70deg);
  -ms-transform: rotateY(-70deg);
  -o-transform: rotateY(-70deg);
  transform: rotateY(-70deg);
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease 0s;
}
.modal.modal-3d-flip-horizontal.in .modal-dialog {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.modal.modal-3d-flip-vertical {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-flip-vertical .modal-dialog {
  -webkit-transform: rotateX(-70deg);
  -ms-transform: rotateX(-70deg);
  -o-transform: rotateX(-70deg);
  transform: rotateX(-70deg);
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease 0s;
}
.modal.modal-3d-flip-vertical.in .modal-dialog {
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.modal.modal-3d-sign {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-sign .modal-dialog {
  -webkit-transform: rotateX(-60deg);
  -ms-transform: rotateX(-60deg);
  -o-transform: rotateX(-60deg);
  transform: rotateX(-60deg);
  -webkit-transform-origin: 50% 0 0;
  -moz-transform-origin: 50% 0 0;
  -ms-transform-origin: 50% 0 0;
  transform-origin: 50% 0 0;
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease 0s;
}
.modal.modal-3d-sign.in .modal-dialog {
  -webkit-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.modal.modal-super-scaled .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(2);
  transition: all 0.3s ease 0s;
}
.modal.modal-super-scaled.in .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.modal.modal-just-me .modal-dialog {
  opacity: 0;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  transition: all 0.3s ease 0s;
}
.modal.modal-just-me .modal-backdrop {
  background-color: #ffffff;
}
.modal.modal-just-me.in {
  background: #fff;
}
.modal.modal-just-me.in .modal-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.modal.modal-just-me.in .modal-backdrop {
  opacity: 1;
}
.modal.modal-3d-slit {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-3d-slit .modal-dialog {
  opacity: 0;
  -ms-transform: translateZ(-3000px) rotateY(90deg);
  -webkit-transform: translateZ(-3000px) rotateY(90deg);
  transform: translateZ(-3000px) rotateY(90deg);
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.5s ease 0s;
}
.modal.modal-3d-slit.in .modal-dialog {
  animation-duration: .7s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-name: slit;
}
.modal.modal-rotate-from-bottom {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-rotate-from-bottom .modal-dialog {
  -ms-transform: translateY(100%) rotateX(90deg);
  -webkit-transform: translateY(100%) rotateX(90deg);
  transform: translateY(100%) rotateX(90deg);
  -webkit-transform-origin: 0 100% 0;
  -moz-transform-origin: 0 100% 0;
  -ms-transform-origin: 0 100% 0;
  transform-origin: 0 100% 0;
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out 0s;
}
.modal.modal-rotate-from-bottom.in .modal-dialog {
  -ms-transform: translateY(0%) rotateX(0deg);
  -webkit-transform: translateY(0%) rotateX(0deg);
  transform: translateY(0%) rotateX(0deg);
}
.modal.modal-rotate-from-left {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}
.modal.modal-rotate-from-left .modal-dialog {
  -ms-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  -webkit-transform-origin: 0 100% 0;
  -moz-transform-origin: 0 100% 0;
  -ms-transform-origin: 0 100% 0;
  transform-origin: 0 100% 0;
  -ms-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.3s ease-out 0s;
}
.modal.modal-rotate-from-left.in .modal-dialog {
  -ms-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  -webkit-transform: translateZ(0px) translateX(0%) rotateY(0deg);
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
}
@-webkit-keyframes slit {
  50% {
    opacity: 0.5;
    -ms-transform: translateZ(-250px) rotateY(89deg);
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    transform: translateZ(-250px) rotateY(89deg);
  }
  100% {
    opacity: 1;
    -ms-transform: translateZ(0px) rotateY(0deg);
    -webkit-transform: translateZ(0px) rotateY(0deg);
    transform: translateZ(0px) rotateY(0deg);
  }
}
@keyframes slit {
  50% {
    opacity: 0.5;
    -ms-transform: translateZ(-250px) rotateY(89deg);
    -webkit-transform: translateZ(-250px) rotateY(89deg);
    transform: translateZ(-250px) rotateY(89deg);
  }
  100% {
    opacity: 1;
    -ms-transform: translateZ(0px) rotateY(0deg);
    -webkit-transform: translateZ(0px) rotateY(0deg);
    transform: translateZ(0px) rotateY(0deg);
  }
}
.tooltip-inner {
  padding: 6px 12px;
}
.tooltip-primary + .tooltip .tooltip-inner {
  color: #ffffff;
  background-color: #c03b40;
}
.tooltip-primary + .tooltip.top .tooltip-arrow {
  border-top-color: #c03b40;
}
.tooltip-primary + .tooltip.right .tooltip-arrow {
  border-right-color: #c03b40;
}
.tooltip-primary + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #c03b40;
}
.tooltip-primary + .tooltip.left .tooltip-arrow {
  border-left-color: #c03b40;
}
.tooltip-success + .tooltip .tooltip-inner {
  color: #ffffff;
  background-color: #4caf50;
}
.tooltip-success + .tooltip.top .tooltip-arrow {
  border-top-color: #4caf50;
}
.tooltip-success + .tooltip.right .tooltip-arrow {
  border-right-color: #4caf50;
}
.tooltip-success + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #4caf50;
}
.tooltip-success + .tooltip.left .tooltip-arrow {
  border-left-color: #4caf50;
}
.tooltip-info + .tooltip .tooltip-inner {
  color: #ffffff;
  background-color: #00bcd4;
}
.tooltip-info + .tooltip.top .tooltip-arrow {
  border-top-color: #00bcd4;
}
.tooltip-info + .tooltip.right .tooltip-arrow {
  border-right-color: #00bcd4;
}
.tooltip-info + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #00bcd4;
}
.tooltip-info + .tooltip.left .tooltip-arrow {
  border-left-color: #00bcd4;
}
.tooltip-warning + .tooltip .tooltip-inner {
  color: #ffffff;
  background-color: #ff9800;
}
.tooltip-warning + .tooltip.top .tooltip-arrow {
  border-top-color: #ff9800;
}
.tooltip-warning + .tooltip.right .tooltip-arrow {
  border-right-color: #ff9800;
}
.tooltip-warning + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ff9800;
}
.tooltip-warning + .tooltip.left .tooltip-arrow {
  border-left-color: #ff9800;
}
.tooltip-danger + .tooltip .tooltip-inner {
  color: #ffffff;
  background-color: #c03b40;
}
.tooltip-danger + .tooltip.top .tooltip-arrow {
  border-top-color: #c03b40;
}
.tooltip-danger + .tooltip.right .tooltip-arrow {
  border-right-color: #c03b40;
}
.tooltip-danger + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #c03b40;
}
.tooltip-danger + .tooltip.left .tooltip-arrow {
  border-left-color: #c03b40;
}
.tooltip-rotate + .tooltip {
  animation: tooltip-rotate3d 1s ease 0.1s forwards;
  opacity: 0;
}
@-webkit-keyframes tooltip-rotate3d {
  0% {
    opacity: 0;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes tooltip-rotate3d {
  0% {
    opacity: 0;
    transform: rotate(15deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}
.tooltip-scale + .tooltip {
  animation: tooltip-scale3d 1s ease 0s forwards;
}
@-webkit-keyframes tooltip-scale3d {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
    transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
    transform: scale3d(1, 1, 1) translate(50%, 50%);
  }
}
@keyframes tooltip-scale3d {
  0% {
    opacity: 0;
    transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate(50%, 50%);
  }
}
.popover {
  padding: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.popover.bottom > .arrow:after {
  border-bottom-color: #eeeeee;
}
.popover-content {
  padding: 20px;
}
.popover-primary + .popover .popover-title {
  color: #ffffff;
  background-color: #c03b40;
  border-color: #c03b40;
}
.popover-primary + .popover.bottom .arrow {
  border-bottom-color: #c03b40;
}
.popover-primary + .popover.bottom .arrow:after {
  border-bottom-color: #c03b40;
}
.popover-success + .popover .popover-title {
  color: #ffffff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.popover-success + .popover.bottom .arrow {
  border-bottom-color: #4caf50;
}
.popover-success + .popover.bottom .arrow:after {
  border-bottom-color: #4caf50;
}
.popover-info + .popover .popover-title {
  color: #ffffff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.popover-info + .popover.bottom .arrow {
  border-bottom-color: #00bcd4;
}
.popover-info + .popover.bottom .arrow:after {
  border-bottom-color: #00bcd4;
}
.popover-warning + .popover .popover-title {
  color: #ffffff;
  background-color: #ff9800;
  border-color: #ff9800;
}
.popover-warning + .popover.bottom .arrow {
  border-bottom-color: #ff9800;
}
.popover-warning + .popover.bottom .arrow:after {
  border-bottom-color: #ff9800;
}
.popover-danger + .popover .popover-title {
  color: #ffffff;
  background-color: #c03b40;
  border-color: #c03b40;
}
.popover-danger + .popover.bottom .arrow {
  border-bottom-color: #c03b40;
}
.popover-danger + .popover.bottom .arrow:after {
  border-bottom-color: #c03b40;
}
.popover-rotate + .popover {
  animation: popover-rotate3d 1s ease 0.1s forwards;
  opacity: 0;
}
@-webkit-keyframes popover-rotate3d {
  0% {
    opacity: 0;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes popover-rotate3d {
  0% {
    opacity: 0;
    transform: rotate(15deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0deg);
  }
}
.popover-scale + .popover {
  animation: popover-scale3d 1s ease 0s forwards;
}
@-webkit-keyframes popover-scale3d {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
    transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1) translate(50%, 50%);
    transform: scale3d(1, 1, 1) translate(50%, 50%);
  }
}
@keyframes popover-scale3d {
  0% {
    opacity: 0;
    transform: scale3d(0.7, 0.3, 1) translate(50%, 50%);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate(50%, 50%);
  }
}
.carousel-control {
  min-width: 50px;
}
.carousel-control:hover,
.carousel-control:focus {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.carousel-control .icon {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
}
.carousel-control.left .icon {
  left: 50%;
  margin-left: -8px;
}
.carousel-control.right .icon {
  right: 50%;
  margin-right: -8px;
}
.carousel-caption h1,
.carousel-caption h2,
.carousel-caption h3,
.carousel-caption h4,
.carousel-caption h5,
.carousel-caption h6 {
  color: inherit;
}
.carousel-indicators {
  margin-bottom: 0;
}
.carousel-indicators li {
  margin: 3px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
}
.carousel-indicators .active {
  width: 10px;
  height: 10px;
  margin: 3px;
}
.carousel-indicators-scaleup li {
  border: none;
  transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
}
.carousel-indicators-scaleup .active {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
}
.carousel-indicators-fillin li {
  background-color: transparent;
  box-shadow: 0 0 0 2px #fff inset;
  transition: box-shadow 0.3s ease 0s;
}
.carousel-indicators-fillin .active {
  box-shadow: 0 0 0 8px #fff inset;
}
.carousel-indicators-fall li {
  position: relative;
  transition: transform 0.3s ease 0s, background-color 0.3s ease 0s;
}
.carousel-indicators-fall li:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: translate(0%, -200%);
  -ms-transform: translate(0%, -200%);
  -o-transform: translate(0%, -200%);
  transform: translate(0%, -200%);
  transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
}
.carousel-indicators-fall .active {
  background-color: transparent;
  -webkit-transform: translate(0, 200%);
  -ms-transform: translate(0, 200%);
  -o-transform: translate(0, 200%);
  transform: translate(0, 200%);
}
.carousel-indicators-fall .active:after {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
}
@media screen and (min-width: 768px) {
  .carousel-control .icon {
    width: 48px;
    height: 48px;
    margin-top: -24px;
    font-size: 48px;
  }
  .carousel-control.left .icon {
    margin-left: -24px;
  }
  .carousel-control.right .icon {
    margin-right: -24px;
  }
}
.vertical-align {
  font-size: 0;
}
.vertical-align:before {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  content: "";
}
.vertical-align-middle,
.vertical-align-bottom {
  display: inline-block;
  max-width: 100%;
  font-size: 14px;
}
.vertical-align-middle {
  vertical-align: middle;
}
.vertical-align-bottom {
  vertical-align: bottom;
}
.inline {
  display: inline !important;
}
.inline-block {
  display: inline-block !important;
}
.block {
  display: block !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  /* for IE */
  white-space: nowrap;
}
.text-break {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  white-space: normal;
}
.text-nowrap {
  white-space: nowrap;
}
.text-top {
  vertical-align: top !important;
}
.text-middle {
  vertical-align: middle !important;
}
.text-bottom {
  vertical-align: bottom !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-unset {
  font-weight: unset !important;
}
.font-weight-100 {
  font-weight: 100 !important;
}
.font-weight-200 {
  font-weight: 200 !important;
}
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}
.font-weight-light {
  font-weight: 100 !important;
}
.font-weight-normal {
  font-weight: 300 !important;
}
.font-weight-medium {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.font-size-0 {
  font-size: 0 !important;
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-26 {
  font-size: 26px !important;
}
.font-size-30 {
  font-size: 30px !important;
}
.font-size-40 {
  font-size: 40px !important;
}
.font-size-50 {
  font-size: 50px !important;
}
.font-size-60 {
  font-size: 60px !important;
}
.font-size-70 {
  font-size: 70px !important;
}
.font-size-80 {
  font-size: 80px !important;
}
.visible-xlg {
  display: none !important;
}
.visible-xlg-block,
.visible-xlg-inline,
.visible-xlg-inline-block {
  display: none !important;
}
@media (min-width: 1600px) {
  .visible-xlg {
    display: block !important;
  }
  table.visible-xlg {
    display: table !important;
  }
  tr.visible-xlg {
    display: table-row !important;
  }
  th.visible-xlg,
  td.visible-xlg {
    display: table-cell !important;
  }
}
@media (min-width: 1600px) {
  .visible-xlg-block {
    display: block !important;
  }
}
@media (min-width: 1600px) {
  .visible-xlg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-xlg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1600px) {
  .hidden-xlg {
    display: none !important;
  }
}
